import ApplicationRecord from '@/models/application_record'
import { Investment } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
export default class Account extends ApplicationRecord {
  static jsonapiType = 'accounts'

  @Attr({ persist: false }) type = 'Account'

  @Attr name!: string
  @Attr accountType!: string
  @Attr active!: boolean
  @Attr isInvestmentAccount!: boolean
  @Attr clearedBalance!: number
  @Attr pendingBalance!: number
  @Attr lastReconciledAt!: Date | null
  @Attr lastBooksVerificationAt!: Date | null
  @Attr maxDaysWithoutReconcile!: number | null

  @Attr defaultInvestmentId!: string

  @BelongsTo() defaultInvestment!: Investment | null

  get displayName (): string {
    let result = this.name
    if (!this.active) {
      result += ' (CLOSED)'
    }
    return result
  }

  get workingBalance (): number {
    return Number(this.clearedBalance) + Number(this.pendingBalance)
  }
}
