import ApplicationRecord from '@/models/application_record'

import {
  TransactionStatus,
  TransactionCategory,
  RecurrenceRule,
  Investment,
  Account,
  Payee,
  Note,
  User
} from '@/models'

import {
  BelongsTo,
  HasMany,
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class AccountTransaction extends ApplicationRecord {
  static jsonapiType = 'account_transactions'

  @Attr transactionDate!: Date | null
  @Attr inflow!: number | null
  @Attr outflow!: number | null
  @Attr draftType!: string
  @Attr isEstimate!: boolean
  @Attr originalOccurrenceDate!: Date | null
  @Attr recurrenceEndDate!: Date | null
  @Attr noteCount!: number
  @Attr accountId!: string
  @Attr transactionStatusId!: string
  @Attr transactionCategoryId!: string | null
  @Attr investmentId!: string | null
  @Attr parentTransactionId!: string | null
  @Attr rootTransactionId!: string | null
  @Attr reverseTransactionId!: string | null
  @Attr seriesId!: string | null
  @Attr recurrenceRuleId!: string | null
  @Attr payeeType!: string | null
  @Attr payeeId!: string | null
  @Attr createdById!: string | null
  @Attr updatedById!: string | null
  @Attr isBooksVerification!: boolean
  @Attr persistForFutureFrom!: string | undefined
  @Attr originalTransactionDate!: Date | undefined

  @Attr({ persist: false }) seriesEndDate!: Date | null
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date

  @BelongsTo() account!: Account
  @BelongsTo() transactionStatus!: TransactionStatus
  @BelongsTo() transactionCategory!: TransactionCategory | null
  @BelongsTo() investment!: Investment | null
  @BelongsTo() payee!: Payee | null
  @BelongsTo() recurrenceRule!: RecurrenceRule | null
  @BelongsTo() createdBy!: User
  @BelongsTo() updatedBy!: User
  @BelongsTo() parentTransaction!: AccountTransaction | null
  @BelongsTo() rootTransaction!: AccountTransaction | null
  @BelongsTo() reverseTransaction!: AccountTransaction | null

  @HasMany() notes!: Note[]

  get type (): string {
    if (!this.id && !this.parentTransactionId) {
      return 'new'
    } else if (!this.id && this.parentTransactionId) {
      return 'generated'
    } else if (this.id && this.parentTransactionId) {
      return 'exception'
    } else if (this.id && !this.parentTransactionId) {
      return 'single'
    }
    return 'unknown'
  }

  get uid (): string {
    if (!this.id && !this.parentTransactionId) {
      return 'n'
    } else if (!this.id && this.parentTransactionId) {
      let uniqueId = this.parentTransactionId
      if (this.rootTransactionId) {
        uniqueId += this.rootTransactionId
      }
      return 'g-' + uniqueId + '-' + this.originalOccurrenceDate + '-' + this.reverseTransactionId
    }
    return this.id as string
  }

  get isRecurring (): boolean {
    if (this.recurrenceRule || this.parentTransactionId) {
      return true
    }
    return false
  }
}
