import ApplicationRecord from '@/models/application_record'
import { Operation, Valuation } from '@/models'

import {
  Model,
  Attr,
  HasMany
} from 'spraypaint'

@Model()
export class MethodTemplate extends ApplicationRecord {
  static jsonapiType = 'method_templates'

  @Attr name!: string
  @HasMany() operations!: Operation[]
  @HasMany() valuations!: Valuation[]

  static mapTemplateToOperations (operations: Operation[], options: { [key: string]: any }) {
    const newOperations = operations.map((op, index) => {
      return Operation.makeDefaultRecord({
        operationalType: options.operationalType,
        operationalId: options?.operationalId || '',
        sequenceIndex: op.sequenceIndex || index,
        operator: op.operator,
        amount: op.amount,
        label: op.label,
        id: ''
      })
    })
    return newOperations
  }
}

export default MethodTemplate
