




































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'

import Dialog from 'primevue/dialog'
import Button from 'primevue/button'

Vue.component('Dialog', Dialog)
Vue.component('Button', Button)

@Component
export default class AppDialog extends Vue {
  @Prop() buttons!: any[]
  @Prop() items!: string[]
  @Prop() content!: string
  @Prop() subheader!: string
  @Prop() header!: string
  @Prop() show!: boolean

  showDialog = false

  @Watch('show')
  onShowChanged (val: boolean) {
    this.showDialog = val
  }

  @Emit()
  hide () { return true }
}
