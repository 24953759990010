import ApplicationRecord from '@/models/application_record'

import {
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class TransactionStatus extends ApplicationRecord {
  static jsonapiType = 'transaction_statuses'

  @Attr name!: string
}
