import ApplicationRecord from '@/models/application_record'
import AttachmentBlobModel from '@/models/attachment_blob'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
class AttachmentBlob extends AttachmentBlobModel {}

@Model()
export default class Attachment extends ApplicationRecord {
  static jsonapiType = 'attachments'

  @Attr name!: string
  @Attr recordType!: string
  @Attr recordId!: string

  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) serviceUrl!: string

  @BelongsTo() blob!: AttachmentBlob
}
