import ApplicationRecord from '@/models/application_record'

import {
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class Payee extends ApplicationRecord {
  static jsonapiType = 'payees'

  @Attr({ persist: false }) type = 'Payee'

  @Attr name!: string
  @Attr mostRecentTransactionCategoryId!: string
}
