
import ApplicationRecord from '@/models/application_record'
import { Membership, Role } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
export default class MemberRole extends ApplicationRecord {
  static jsonapiType = 'member_roles'

  @Attr memberId!: string
  @Attr roleId!: string

  @BelongsTo() member!: Membership
  @BelongsTo() role!: Role
}
