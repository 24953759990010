


















































































































import { Component, Vue } from 'vue-property-decorator'

import { TransactionCategory } from '@/models'

import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'

Vue.component('Dialog', Dialog)
Vue.component('Dropdown', Dropdown)

@Component
export default class TransactionCategoryManager extends Vue {
  loading = true
  processing = false
  categories: TransactionCategory[] = []
  editingCategories: TransactionCategory[] = []
  categoryPendingDelete: TransactionCategory | null = null
  selectedCategory: TransactionCategory | null = null
  showCategoryDeleteDialog = false
  reassignmentCategory: TransactionCategory | null = null

  get reassignmentCategories () {
    if (!this.categoryPendingDelete) return this.categories
    return this.categories.filter(cat => cat.id !== this.categoryPendingDelete!.id)
  }

  async mounted () {
    await this.getTransactionCategories()
    this.loading = false
  }

  async getTransactionCategories () {
    this.categories = (await TransactionCategory
      .per(10000)
      .order('name')
      .all()).data
  }

  enterEditMode (category: TransactionCategory) {
    this.getTransactionCategories()
    this.selectedCategory = category
    this.editingCategories = [category]
  }

  exitEditMode () {
    if (this.editingCategories.length > 0) {
      this.getTransactionCategories()
    }
    this.editingCategories = []
  }

  promptForDeleteCategory (category: TransactionCategory) {
    this.exitEditMode()
    this.selectedCategory = category
    this.categoryPendingDelete = category
    this.showCategoryDeleteDialog = true
  }

  async saveCategory (category: TransactionCategory) {
    await category.save()
    this.exitEditMode()
  }

  async deleteCategory () {
    this.processing = true
    if (!this.categoryPendingDelete) return

    // flag to reassign existing transactions if user chose new category
    if (this.reassignmentCategory && this.reassignmentCategory.id) {
      this.categoryPendingDelete.reassignmentId = this.reassignmentCategory.id
      await this.categoryPendingDelete.save()
    }

    await this.categoryPendingDelete.destroy()
    this.getTransactionCategories()
    this.showCategoryDeleteDialog = false
    this.processing = false
  }

  onRowSelect () {
    this.exitEditMode()
  }
}
