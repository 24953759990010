


































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AccountTransaction } from '@/models'

import AppColorTag from '@/components/AppColorTag'

Vue.component('AppColorTag', AppColorTag)

@Component
export default class TransactionDetailSection extends Vue {
  @Prop() transaction!: AccountTransaction
  @Prop() transactionPreview!: AccountTransaction
  @Prop() nextOccurrence!: AccountTransaction
  @Prop({ default: () => { return false } }) showCategory!: boolean

  get t () {
    return this.transaction ? this.transaction : new AccountTransaction()
  }

  get preview () {
    return this.transactionPreview ? this.transactionPreview : this.t
  }

  get next () {
    return this.nextOccurrence ? this.nextOccurrence : this.t
  }

  get canChange () {
    return !!this.transactionPreview
  }

  get transactionDateChanged () {
    return this.canChange && this.next.transactionDate !== this.preview.transactionDate
  }

  get recurrenceRuleChanged () {
    // not possible to change the recurrence rule and end up on the next occurrence preview modal
    return false
  }

  get accountChanged () {
    return this.canChange && String(this.next.accountId) !== String(this.t.account ? this.t.account.id : null)
  }

  get investmentChanged () {
    return this.canChange && String(this.next.investmentId) !== String(this.t.investment ? this.t.investment.id : null)
  }

  get transactionStatusChanged () {
    return this.canChange && String(this.next.transactionStatusId) !== String(this.t.transactionStatus ? this.t.transactionStatus.id : null)
  }

  get payeeChanged () {
    return this.canChange && (String(this.next.payeeId) !== String(this.t.payee ? this.t.payee.id : null) || this.next.payeeType !== this.t.payeeType)
  }

  get estimateChanged () {
    return this.canChange && this.next.isEstimate !== this.t.isEstimate
  }

  get draftTypeChanged () {
    return this.canChange && this.next.draftType !== this.t.draftType
  }

  get amountChanged () {
    return this.canChange && (Number(this.next.inflow) !== Number(this.t.inflow) || Number(this.next.outflow) !== Number(this.t.outflow))
  }

  get transactionCategoryChanged () {
    return this.canChange && String(this.next.transactionCategoryId) !== String(this.t.transactionCategory ? this.t.transactionCategory.id : null)
  }
}
