import ApplicationRecord from '@/models/application_record'
import { Role, Permission } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
export default class RolePermission extends ApplicationRecord {
  static jsonapiType = 'role_permissions'

  @Attr roleId!: string
  @Attr permissionId!: string

  @BelongsTo() role!: Role
  @BelongsTo() permission!: Permission
}
