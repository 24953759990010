

































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'
import { AccountTransactionSaver } from './services/account_transaction_saver'

import {
  PrimeVueRowSelectEvent,
  PrimeVueRowEditEvent,
  PrimeVueQueryEvent,
  PrimeVuePageEvent,
  PrimeVueSortEvent,
  TransactionDateShortcut,
  DateRange,
  WhereFilter
} from './types'

import dayjs from 'dayjs'

import Account from '@/models/account'
import AccountTransaction from '@/models/account_transaction'
import Investment from '@/models/investment'
import Payee from '@/models/payee'
import TransactionStatus from '@/models/transaction_status'
import TransactionCategory from '@/models/transaction_category'
import RecurrenceRule from '@/models/recurrence_rule'
import Note from '@/models/note'
import Color from '@/models/color'

import AppInputCurrency from '@/components/AppInputCurrency'
import AutoComplete from 'primevue/autocomplete'
import Calendar from 'primevue/calendar'
import ContextMenu from 'primevue/contextmenu'
import DataTable from 'primevue/datatable'
import Dropdown from 'primevue/dropdown'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import OverlayPanel from 'primevue/overlaypanel'
import ToggleButton from 'primevue/togglebutton'
import Tooltip from 'primevue/tooltip'
import Dialog from 'primevue/dialog'
import AppDialog from '@/components/AppDialog'
import AppColorTag from '@/components/AppColorTag'
import AccountTransactionDetailPanel from './components/AccountTransactionDetailPanel.vue'
import TransactionCategoryManager from './components/TransactionCategoryManager.vue'
import EquityEditor from './components/EquityEditor.vue'
import AccountTransactionExporter from './components/AccountTransactionExporter.vue'
import ColumnDisplayEditor from './components/ColumnDisplayEditor.vue'
import RecurringTransactionSaveDialog from './components/RecurringTransactionSaveDialog.vue'

Vue.component('AppInputCurrency', AppInputCurrency)
Vue.component('AutoComplete', AutoComplete)
Vue.component('Calendar', Calendar)
Vue.component('ContextMenu', ContextMenu)
Vue.component('DataTable', DataTable)
Vue.component('Dropdown', Dropdown)
Vue.component('Column', Column)
Vue.component('Button', Button)
Vue.component('InputNumber', InputNumber)
Vue.component('InputText', InputText)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('ToggleButton', ToggleButton)
Vue.component('AppDialog', AppDialog)
Vue.component('AppColorTag', AppColorTag)
Vue.component('Dialog', Dialog)
Vue.component('AccountTransactionDetailPanel', AccountTransactionDetailPanel)
Vue.component('TransactionCategoryManager', TransactionCategoryManager)
Vue.component('EquityEditor', EquityEditor)
Vue.component('AccountTransactionExporter', AccountTransactionExporter)
Vue.component('ColumnDisplayEditor', ColumnDisplayEditor)
Vue.component('RecurringTransactionSaveDialog', RecurringTransactionSaveDialog)

Vue.directive('tooltip', Tooltip)

const Auth = namespace('auth')
const App = namespace('app')

@Component
export default class AppAccountTransactionsTable extends Vue {
  @Auth.Getter currentPermissions!: string
  @App.Action('colors') loadColors!: Function

  @Prop() accountParam!: string | null
  @Prop() payeeParam!: { [key: string]: string } | null
  @Prop() investmentParam!: string | null
  @Prop() draftTypeParam!: string | { not_eq: ['adjustment', 'initial_adjustment'] } | null
  @Prop() recurringParam!: boolean | null
  @Prop() estimateParam!: boolean | null
  @Prop() transactionCategoryParam!: string | null
  @Prop() amountParam!: string | null
  @Prop() transactionStatusParam!: (string | undefined)[]
  @Prop() transactionDateParam!: (string | null)[]
  @Prop() amountTypeParam!: 'inflow' | 'outflow' | null

  @Prop({ default: () => { return [] } }) providedPayees!: Payee[]
  @Prop({ default: () => { return [] } }) providedAccounts!: Account[]
  @Prop({ default: () => { return [] } }) providedInvestments!: Investment[]
  @Prop({ default: () => { return [] } }) providedTransactionStatuses!: TransactionStatus[]
  @Prop({ default: () => { return [] } }) providedTransactionCategories!: TransactionCategory[]

  @Prop() containingView!: string
  @Prop({ default: () => { return [] } }) hiddenColumns!: string[]
  @Prop({ default: () => { return {} } }) defaultParams!: { [investment: string]: Investment }
  @Prop({ default: () => { return '210' } }) scrollHeightDiff!: string
  @Prop({ default: () => { return true } }) showNewTransaction!: boolean
  @Prop({ default: () => { return false } }) showRunningBalance!: boolean
  @Prop({ default: () => { return true } }) enableSorting!: boolean
  @Prop({ default: () => { return false } }) loadTransactionsOnMounted!: boolean
  @Prop({ default: () => { return false } }) readOnly!: boolean

  @Prop({ default: () => { return false } }) parentTriggeredClearAll!: boolean
  selfTriggeredClearAll = false

  loading = true

  missingFields: string[] = []
  accountDisabled = false

  transactions: AccountTransaction[] = []
  transactionPendingSave: AccountTransaction | null = null
  editingTransactions: AccountTransaction[] = []
  selectedTransactions: AccountTransaction[] = []
  relatedTransactions: string[] = []
  reverseSelectedTransaction: string | null = null
  newTransaction = new AccountTransaction()
  transactionWithVisibleNotes: string | null = null
  detailTransaction: AccountTransaction | null = null

  forecastedBalances: number[] = []
  transactionContextMenuModel = [
    {
      label: 'View Details',
      icon: 'pi pi-fw pi-search',
      command: () => this.forceOpenTransactionDetailDialog()
    }
  ]

  showDraftTypeSelectionDialog = false
  showRecurringDeleteDialog = false
  showRequiredFieldsDialog = false
  showRecurringSaveDialog = false
  showSingleDeleteDialog = false
  showDetailDialog = false
  showCategoryManager = false
  showErrorDialog = false

  errorMessage = ''

  page = 1
  pageSize = this.showRunningBalance ? 1000000 : 50
  totalRecords = 0
  sortField = 'transactionDate'
  sortOrder = 1

  accounts: Account[] = []
  payees: Payee[] = []
  payeeOptions: (Account|Payee)[] = []
  transactionCategoryOptions: TransactionCategory[] = []
  investments: Investment[] = []
  transactionStatuses: TransactionStatus[] = []
  transactionCategories: TransactionCategory[] = []
  recurrenceRules: RecurrenceRule[] = []
  draftTypes = [
    'check',
    'cash',
    'credit_card',
    'pay_online',
    'autodraft',
    'transfer',
    'ach',
    'wire'
  ]

  enableCreate = false
  enableReadAccount = false
  enableReadAccountTransaction = false
  enableReadInvestment = false
  enableUpdate = false
  enableDestroy = false

  @Watch('showCategoryManager')
  onHideCategoryManager (newValue: boolean) {
    if (!newValue) {
      this.loading = true
      this.getAccountTransactions()
      this.transactionCategoriesChanged()
    }
  }

  @Watch('providedTransactionCategories')
  onProvidedTransactionCategoriesChanged () {
    this.getTransactionCategories(this.providedTransactionCategories)
  }

  @Watch('parentTriggeredClearAll')
  onChangeParentTriggeredClearAll () {
    // Will be triggered once all filters have been reset
    //  from the parent component, ignoring watcher-caused reloads
    //  until all filter fields have been reset
    if (!this.parentTriggeredClearAll) {
      this.loading = true
      this.getAccountTransactions()
    }
  }

  @Watch('selfTriggeredClearAll')
  onChangeSelfTriggeredClearAll () {
    // Will be triggered once all filters have been reset
    //  from this component, ignoring watcher-caused reloads
    //  until all filter fields have been reset
    if (!this.selfTriggeredClearAll) {
      this.loading = true
      this.getAccountTransactions()
    }
  }

  @Watch('accountParam')
  onChangeAccountParam () {
    if (this.selfTriggeredClearAll) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)

    const selectedAccount: Account | undefined = this.accounts.filter(item => {
      return item.id === this.accountParam
    })[0]
    if (selectedAccount && selectedAccount.id) {
      this.newTransaction.account = selectedAccount
      this.accountDisabled = true
      return
    }
    this.accountDisabled = false
  }

  @Watch('payeeParam')
  onChangePayeeParam () {
    if (this.parentTriggeredClearAll) return
    if (this.selfTriggeredClearAll) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)
  }

  @Watch('transactionDateParam', { deep: true })
  onChangeTransactionDateParam () {
    if (this.selfTriggeredClearAll) return
    if (!this.transactionDateParam ||
      this.transactionDateParam.length < 2 ||
      this.transactionDateParam[1] === null
    ) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)
  }

  @Watch('transactionStatusParam')
  onChangeTransactionStatusParam () {
    if (this.selfTriggeredClearAll) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)
  }

  @Watch('investmentParam')
  onChangeInvestmentParam () {
    if (this.parentTriggeredClearAll) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)
  }

  @Watch('draftTypeParam')
  onChangeDraftTypeParam () {
    if (this.parentTriggeredClearAll) return
    if (this.selfTriggeredClearAll) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)
  }

  @Watch('recurringParam')
  onChangeRecurringParam () {
    if (this.parentTriggeredClearAll) return
    if (this.selfTriggeredClearAll) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)
  }

  @Watch('estimateParam')
  onChangeEstimateParam () {
    if (this.parentTriggeredClearAll) return
    if (this.selfTriggeredClearAll) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)
  }

  @Watch('transactionCategoryParam')
  onChangeTransactionCategoryParam () {
    if (this.parentTriggeredClearAll) return
    if (this.selfTriggeredClearAll) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)
  }

  @Watch('amountParam')
  onChangeAmountParam () {
    if (this.parentTriggeredClearAll) return
    if (this.selfTriggeredClearAll) return
    this.loading = true
    this.getAccountTransactions(this.page, this.pageSize)
  }

  get selectedAccounts () {
    const accounts = []
    const relevantAccounts = (this.accountParam && !(this.accountParam.length === 1 && this.accountParam[0] === null))
      ? this.accountParam
      : this.accounts.map(item => item.id).filter(item => { return item !== null })
    for (let i = 0; i < relevantAccounts.length; i++) {
      const account = this.accounts.filter(item => {
        return String(item.id) === String(relevantAccounts[i])
      })[0]
      if (account) {
        accounts.push(account)
      }
    }
    return accounts.sort((a, b) => { return a.displayName.localeCompare(b.displayName) })
  }

  get totalWorkingBalance () {
    let total = 0.0
    for (let i = 0; i < this.selectedAccounts.length; i++) {
      const account = this.selectedAccounts[i]
      if (account) {
        total += Number(account.clearedBalance) + Number(account.pendingBalance)
      }
    }
    return total
  }

  get newTransactionIsValid () {
    const t = this.newTransaction
    if (t.transactionDate === undefined || t.transactionDate === null) return false
    if (t.account === undefined || t.account === null) return false
    if (t.transactionStatus === undefined || t.transactionStatus === null) return false
    if (t.transactionStatus.name !== 'Forecast' && !t.draftType) return false
    if (!t.inflow && !t.outflow) return false
    return true
  }

  get formattedTransactionDateParam () {
    const endDate = dayjs(this.transactionDateParam[1] as string).format('YYYY-MM-DD')
    const formattedTransactionDateParam: DateRange = {
      lte: endDate,
      gte: null
    }
    if (this.transactionDateParam[0] !== null) {
      const startDate = dayjs(this.transactionDateParam[0] as string).format('YYYY-MM-DD')
      formattedTransactionDateParam.gte = startDate
    }
    return formattedTransactionDateParam
  }

  get formattedSortParam () {
    let sortParam: { [key: string]: string } = {}
    if (this.sortField) {
      sortParam[this.sortField] = this.sortOrder === 1 ? 'asc' : 'desc'
    } else {
      sortParam = {}
    }
    return JSON.parse(JSON.stringify(sortParam))
  }

  get filteredAccounts () {
    return this.accounts.filter((account) => {
      if (account.name === 'All Accounts') return false
      return true
    })
  }

  get minTableWidth () {
    if (this.containingView === 'forecast') return

    let width = 1420
    if (this.accountParam) width -= 220
    if (this.hiddenColumns.includes('notes')) width -= 50
    if (this.hiddenColumns.includes('draftType')) width -= 100
    if (this.hiddenColumns.includes('equity')) width -= 75
    if (this.hiddenColumns.includes('estimate')) width -= 50
    if (this.hiddenColumns.includes('transactionCategory')) width -= 180
    return String(width) + 'px'
  }

  async mounted () {
    this.getCurrentPermissions()
    if (this.loadTransactionsOnMounted) {
      this.readOnly ? await this.getAccountTransactions() : this.getAccountTransactions()
    }
    if (!this.readOnly) {
      await Promise.all([
        this.getTransactionStatuses(this.providedTransactionStatuses),
        this.getTransactionCategories(this.providedTransactionCategories),
        this.getRecurrenceRules(),
        this.getInvestments(this.providedInvestments),
        this.getAccounts(this.providedAccounts),
        this.getPayees(this.providedPayees)
      ])
    }
    this.loadColors()
    if (this.showNewTransaction) {
      this.resetNewTransaction()
    }
    window.addEventListener('resize', this.determineTableOverflowBehavior)
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableCreate = permissions.includes('create_account_transaction')
    this.enableReadAccount = permissions.includes('read_account')
    this.enableReadAccountTransaction = permissions.includes('read_account_transaction')
    this.enableReadInvestment = permissions.includes('read_investment')
    this.enableUpdate = permissions.includes('update_account_transaction')
    this.enableDestroy = permissions.includes('destroy_account_transaction')
  }

  async getAccountTransactionsPage (page: number, pageSize: number) {
    if (!this.enableReadAccountTransaction) return

    const whereFilter: WhereFilter = {
      accountId: '',
      payeeType: this.payeeParam ? this.payeeParam.type : '',
      payeeId: this.payeeParam ? this.payeeParam.id : '',
      investmentId: this.investmentParam,
      draftType: this.draftTypeParam,
      isRecurrence: this.recurringParam,
      isEstimate: this.estimateParam,
      transactionCategoryId: this.transactionCategoryParam,
      absoluteAmount: { eq: this.amountParam },
      transactionDate: this.formattedTransactionDateParam,
      transactionStatusId: this.transactionStatusParam,
      inflow: this?.amountTypeParam === 'outflow' ? { eq: 'null' } : null,
      outflow: this?.amountTypeParam === 'inflow' ? { eq: 'null' } : null
    }

    if (this.accountParam && !(this.accountParam.length === 1 && this.accountParam[0] === null)) {
      whereFilter.accountId = this.accountParam
    }

    return await AccountTransaction
      .includes('account')
      .includes('transactionStatus')
      .includes('transactionCategory')
      .includes('investment')
      .includes('payee')
      .includes('recurrenceRule')
      .includes('parentTransaction')
      .includes('rootTransaction')
      .includes('updatedBy')
      .includes('createdBy')
      .selectExtra(['seriesEndDate'])
      .where(whereFilter)
      .order(this.formattedSortParam)
      .order('parentTransactionId')
      .order('id')
      .page(page)
      .per(pageSize)
      .stats({ total: 'count' })
      .all()
  }

  @Emit()
  async getAccountTransactions (page = 1, pageSize = this.pageSize, selectRelatedOnComplete = true, triggerCalendarRefreshOnComplete = true) {
    this.page = page
    this.pageSize = pageSize

    const response = await this.getAccountTransactionsPage(this.page, this.pageSize)
    if (!response) return

    this.transactions = response.data
    this.totalRecords = response.meta.stats.total.count

    this.resetEditingTransactions()
    this.selectReverseTransaction()

    if (selectRelatedOnComplete) {
      this.selectRelatedTransactions()
    }

    if (this.showRunningBalance) {
      this.computeRunningBalances()
    }

    this.loading = false
    setTimeout(() => {
      this.determineTableOverflowBehavior()
      this.initDoubleClickBehavior()
    })

    return triggerCalendarRefreshOnComplete
  }

  @Emit()
  transactionCategoriesChanged () {
    return null
  }

  async getTransactionStatuses (providedTransactionStatuses: TransactionStatus[] = []) {
    if (providedTransactionStatuses && providedTransactionStatuses.length > 0) {
      this.transactionStatuses = providedTransactionStatuses
    } else {
      this.transactionStatuses = (await TransactionStatus.per(1000).all()).data
    }

    if (!this.transactionStatusParam || this.transactionStatusParam.length === 0) {
      this.transactionStatusParam = this.transactionStatuses.filter(item => {
        return ['Forecast', 'Pending'].includes(item.name)
      }).map(item => { return item.id })
    }

    this.newTransaction.transactionStatus = this.transactionStatuses.filter((status) => {
      return status.name === 'Forecast'
    })[0]
  }

  async getTransactionCategories (providedTransactionCategories: TransactionCategory[] = []) {
    if (providedTransactionCategories && providedTransactionCategories.length > 0) {
      this.transactionCategories = providedTransactionCategories
    } else {
      this.transactionCategories = (await TransactionCategory.per(10000).order('name').all()).data
    }
  }

  async getRecurrenceRules () {
    this.recurrenceRules = (await RecurrenceRule.per(1000).all()).data
  }

  async getInvestments (providedInvestments: Investment[] = []) {
    if (!this.enableReadInvestment) return
    if (providedInvestments && providedInvestments.length > 0) {
      this.investments = providedInvestments
    } else {
      this.investments = (await Investment.order('name').per(1000).all()).data
    }
  }

  async getAccounts (providedAccounts: Account[] = []) {
    if (!this.enableReadAccount) return
    if (providedAccounts && providedAccounts.length > 0) {
      this.accounts = providedAccounts
    } else {
      this.accounts = (await Account.includes('defaultInvestment').order('name').per(1000).all()).data
    }
  }

  async getPayees (providedPayees: Payee[] = []) {
    if (!this.enableReadAccountTransaction) return
    if (providedPayees && providedPayees.length > 0) {
      this.payees = providedPayees
    } else {
      this.payees = (await Payee.order('name').per(1000).all()).data
    }
  }

  async getTransactionNotes (transaction: AccountTransaction) {
    if (!this.enableReadAccountTransaction) return

    if (!transaction || !transaction.id) return
    const response = await Note
      .where({
        notableType: 'AccountTransaction',
        notableId: transaction.id
      })
      .includes('updatedBy')
      .order({ createdAt: 'desc' })
      .order('id')
      .per(99999)
      .all()
    transaction.notes = response.data
  }

  async saveSingleTransaction (transaction: AccountTransaction | undefined) {
    this.showRecurringSaveDialog = false

    if (!transaction) {
      if (!this.transactionPendingSave) { return }
      transaction = this.transactionPendingSave
    }
    const saver = new AccountTransactionSaver(transaction, this.payees, this.transactionCategories, this.transactionStatuses, this.$refs)
    const response = await saver.saveSingle()

    if (response.errorMessage) {
      this.errorMessage = response.errorMessage
      this.showErrorDialog = true
    } else {
      const newPayeeAdded = response.transaction.payee && response.requiredNewPayee
      const newCategoryAdded = response.transaction.transactionCategory && response.requiredNewCategory
      if (newPayeeAdded) this.addNewPayeeToList(response.transaction.payee)
      if (newCategoryAdded) this.addNewCategoryToList(response.transaction.transactionCategory)
    }

    this.getAccountTransactions(this.page)
    this.getPayees()

    if (response.wasNew) this.resetNewTransaction()
    this.resetEditingTransactions()
    this.resetSelectedTransactions(transaction)
  }

  async saveAllFutureTransactions (transaction: AccountTransaction | undefined) {
    this.showRecurringSaveDialog = false

    if (!transaction) {
      if (!this.transactionPendingSave) { return }
      transaction = this.transactionPendingSave
    }

    const saver = new AccountTransactionSaver(transaction, this.payees, this.transactionCategories, this.transactionStatuses, this.$refs)
    const response = await saver.saveAllFuture()

    if (response && !response.errorMessage) {
      const newPayeeAdded = response.transaction.payee && response.requiredNewPayee
      const newCategoryAdded = response.transaction.transactionCategory && response.requiredNewCategory
      if (newPayeeAdded) this.addNewPayeeToList(response.transaction.payee)
      if (newCategoryAdded) this.addNewCategoryToList(response.transaction.transactionCategory)
    } else {
      this.errorMessage = response ? response.errorMessage : 'System Error. Please contact application support.'
      this.showErrorDialog = true
    }

    this.getAccountTransactions(this.page, this.pageSize)
    this.getPayees()
  }

  promptForSave (transaction: AccountTransaction | undefined) {
    if (!transaction) {
      if (!this.transactionPendingSave) { return }
      transaction = this.transactionPendingSave
    }

    const valid = this.validateRequiredFieldsForState(transaction, transaction.transactionStatus.name)
    if (!valid) return

    const saver = new AccountTransactionSaver(transaction, this.payees, this.transactionCategories, this.transactionStatuses, this.$refs)
    const saveType = saver.determineSaveType()
    if (saveType === 'single') {
      this.saveSingleTransaction(transaction)
    } else if (saveType === 'recurring') {
      this.saveAllFutureTransactions(transaction)
    } else if (saveType === 'recurringWithPrompt') {
      this.transactionPendingSave = transaction
      this.showRecurringSaveDialog = true
    }
  }

  setDraftTypeOnPendingTransaction (draftType: string) {
    if (!this.transactionPendingSave) return

    this.transactionPendingSave.draftType = draftType
    this.showDraftTypeSelectionDialog = false

    if (this.transactionPendingSave.transactionStatus.name === 'Forecast') {
      this.cycleTransactionStatus(this.transactionPendingSave)
    } else {
      this.promptForSave(this.transactionPendingSave)
    }
  }

  resetNewTransaction () {
    this.newTransaction = new AccountTransaction({
      transactionDate: new Date()
    })

    if (this.accountParam !== null) {
      this.newTransaction.account = this.accounts.filter(item => {
        return item.id === this.accountParam
      })[0]
    }

    this.newTransaction.transactionStatus = this.transactionStatuses.filter((status) => {
      return status.name === 'Forecast'
    })[0]

    this.revalidateInflow(this.newTransaction)
    this.revalidateOutflow(this.newTransaction)
  }

  addNewPayeeToList (payee: Payee) {
    this.payees.push(payee)
    this.payees = this.payees.sort((a, b) => {
      return a.name > b.name ? 1 : 0
    })
  }

  addNewCategoryToList (category: TransactionCategory) {
    this.transactionCategories.push(category)
    this.transactionCategories = this.transactionCategories.sort((a, b) => {
      return a.name > b.name ? 1 : 0
    })
  }

  setMostRecentTransactionCategory (transaction: AccountTransaction) {
    if (transaction.id) return
    if (transaction.payee && transaction.payee.type !== 'Payee') return
    const selectedPayee = this.payees.filter(payee => {
      if (!transaction.payee) return false
      return payee.id === transaction.payee.id
    })[0]
    const mostRecentCategory = this.transactionCategories.filter(category => {
      return category.id === selectedPayee.mostRecentTransactionCategoryId
    })[0]
    transaction.transactionCategory = mostRecentCategory
  }

  async searchPayees (event: PrimeVueQueryEvent) {
    if (!event.query.trim().length) {
      let options: (Account|Payee)[] = this.accounts.filter((account) => {
        return account.name !== 'All Accounts'
      })
      options = options.concat(this.payees)
      this.payeeOptions = options
    } else {
      const accountOptions = this.accounts.filter((account) => {
        if (account.name === 'All Accounts') return false
        return account.name.toLowerCase().includes(event.query.toLowerCase())
      })
      const payeeOptions = this.payees.filter((payee) => {
        return payee.name ? payee.name.toLowerCase().includes(event.query.toLowerCase()) : false
      })
      let options: (Account|Payee)[] = accountOptions
      options = options.concat(payeeOptions)
      this.payeeOptions = options
    }
  }

  async searchTransactionCategories (event: PrimeVueQueryEvent) {
    if (!event.query.trim().length) {
      this.transactionCategoryOptions = [...this.transactionCategories]
    } else {
      this.transactionCategoryOptions = this.transactionCategories.filter((cat) => {
        return cat.name.toLowerCase().includes(event.query.toLowerCase())
      })
    }
  }

  selectRelatedTransactions (transaction: AccountTransaction | null = null) {
    if (!transaction && this.selectedTransactions.length !== 1) {
      this.relatedTransactions = []
      return
    }

    if (!transaction) transaction = this.selectedTransactions[0]
    this.relatedTransactions = this.transactions.filter(item => {
      if (!transaction) { return false }
      return item.seriesId === transaction.seriesId
    }).map(item => { return item.uid })
  }

  selectReverseTransaction () {
    if (!(this.selectedTransactions.length === 1 || this.selectedTransactions.length === 2)) {
      this.reverseSelectedTransaction = null
      return
    }

    let transaction = this.selectedTransactions[0]
    if (this.selectedTransactions.length === 2) {
      if (this.selectedTransactions[0].type === 'new') {
        transaction = this.selectedTransactions[1]
      }
    }

    const reverseTransactions = this.transactions.filter(item => {
      return String(item.id) === String(transaction.reverseTransactionId)
    })

    if (reverseTransactions && reverseTransactions.length === 1) {
      this.reverseSelectedTransaction = reverseTransactions[0].uid
    } else {
      this.reverseSelectedTransaction = null
    }
  }

  onRowSelect (event: PrimeVueRowSelectEvent) {
    this.selectReverseTransaction()
    const editingIds = this.editingTransactions.map(e => e.uid)
    if (!event.data.uid || !editingIds.includes(event.data.uid)) {
      this.cancelCurrentEditModes()
    }
  }

  onRowContextMenu (event: any) {
    if (this.containingView === 'forecast' || this.containingView === 'reports') return
    if (this.$refs && this.$refs.transaction_context_menu) {
      (this.$refs.transaction_context_menu as any).show(event.originalEvent)
    }
  }

  async onRowEditInit (event: PrimeVueRowEditEvent) {
    await this.cancelCurrentEditModes()

    if (this.transactions[event.index].id !== undefined) {
      this.resetSelectedTransactions(this.transactions[event.index])
      this.resetEditingTransactions(this.transactions[event.index])
      this.selectReverseTransaction()
      this.preFormatDateField(this.transactions[event.index])
    } else {
      this.resetSelectedTransactions()
      this.resetEditingTransactions()
    }
  }

  preFormatDateField (transaction: AccountTransaction) {
    transaction.transactionDate = new Date(dayjs(transaction.transactionDate as Date).format('MM/DD/YYYY'))
  }

  onChangeTransactionStatus (transaction: AccountTransaction) {
    if (transaction &&
      transaction.transactionStatus &&
      transaction.transactionStatus.name !== 'Forecast'
    ) {
      transaction.isEstimate = false
    }
  }

  onChangeRecurrenceRule (transaction: AccountTransaction) {
    transaction.recurrenceRuleId = transaction.recurrenceRule ? transaction.recurrenceRule.id! : null
    if (transaction.isRecurring) {
      transaction.transactionStatus = this.transactionStatuses.filter(item => {
        return item.name === 'Forecast'
      })[0]
    }
  }

  validateRequiredFieldsForState (transaction: AccountTransaction, state: string) {
    if (state !== 'Forecast') {
      if (!transaction.draftType && !transaction.payee) {
        this.missingFields = ['Payee', 'Payment Type']
        this.showRequiredFieldsDialog = true
        return false
      } else if (!transaction.payee) {
        this.missingFields = ['Payee']
        this.showRequiredFieldsDialog = true
        return false
      } else if (!transaction.draftType) {
        this.transactionPendingSave = transaction
        this.showDraftTypeSelectionDialog = true
        return false
      }
    }
    return true
  }

  async cycleTransactionStatus (transaction: AccountTransaction) {
    if (!transaction) return

    this.resetSelectedTransactions(transaction)
    this.selectReverseTransaction()

    const nextStates: { [key: string]: string } = {
      Forecast: 'Pending',
      Pending: 'Cleared',
      Cleared: 'Forecast'
    }

    const valid = this.validateRequiredFieldsForState(transaction, nextStates[transaction.transactionStatus.name])
    if (!valid) return

    transaction.transactionStatus = this.transactionStatuses.filter(item => {
      return item.name === nextStates[transaction.transactionStatus.name]
    })[0]

    if (transaction.type === 'generated') {
      transaction.recurrenceRuleId = null
      transaction.recurrenceRule = null
      transaction.isPersisted = false
    }

    const saver = new AccountTransactionSaver(transaction, this.payees, this.transactionCategories, this.transactionStatuses, this.$refs)
    const associations = saver.getAssociationsForTransaction()
    const success = await transaction.save({
      with: associations
    })

    if (!success) {
      // TODO: implement error dialog
      return
    }

    this.getAccountTransactions(this.page)
  }

  sortBy (event: PrimeVueSortEvent) {
    this.loading = true
    this.sortField = event.sortField
    this.sortOrder = event.sortOrder
    this.getAccountTransactions()
  }

  async onPage (event: PrimeVuePageEvent) {
    this.loading = true
    this.getAccountTransactions(event.page + 1, event.rows, true, false)
  }

  resetSelectedTransactions (transaction: AccountTransaction | undefined = undefined) {
    this.reverseSelectedTransaction = null
    if (transaction) {
      this.selectedTransactions = [this.newTransaction, transaction]
      this.selectReverseTransaction()
    } else {
      this.selectedTransactions = [this.newTransaction]
    }
  }

  resetEditingTransactions (transaction: AccountTransaction | undefined = undefined) {
    if (transaction) {
      this.editingTransactions = [this.newTransaction, transaction]
    } else {
      this.editingTransactions = [this.newTransaction]
    }
  }

  async cancelCurrentEditModes (maintainCurrentSelection = true) {
    for (let i = 0; i < this.editingTransactions.length; i++) {
      const e = this.editingTransactions[i]

      let idx = -1
      for (let i = 0; i < this.transactions.length; i++) {
        const t = this.transactions[i]
        if (t.uid === e.uid) {
          idx = i
          break
        }
      }

      if (idx >= 0) {
        if (e.type === 'single' || e.type === 'exception') {
          const freshTransaction: AccountTransaction = (await AccountTransaction
            .includes('account')
            .includes('transactionStatus')
            .includes('transactionCategory')
            .includes('investment')
            .includes('payee')
            .includes('recurrenceRule')
            .includes('parentTransaction')
            .includes('rootTransaction')
            .selectExtra(['seriesEndDate'])
            .find(e.id as string)
          ).data
          this.$set(this.transactions, idx, freshTransaction)
          this.resetEditingTransactions()
        } else {
          await this.getAccountTransactions(this.page, this.pageSize, false)
        }
      }
    }

    if (maintainCurrentSelection && this.selectedTransactions.length === 2) {
      this.editingTransactions = [this.newTransaction]
    }
  }

  openCategoryManager () {
    this.showCategoryManager = true
  }

  openTransactionDetailDialog (transaction: AccountTransaction = this.selectedTransactions[0]) {
    if (this.containingView === 'forecast' || this.containingView === 'reports') return

    // this is used to open the detail panel via click/double-click
    if (this.showDetailDialog) return
    if (!transaction) return
    if (this.editingTransactions.includes(transaction)) return

    this.resetEditingTransactions()
    this.detailTransaction = transaction
    this.showDetailDialog = true
  }

  forceOpenTransactionDetailDialog () {
    // this is used to open the detail panel via context menu
    this.resetEditingTransactions()
    this.detailTransaction = this.selectedTransactions[0]
    this.showDetailDialog = true
    this.getTransactionNotes(this.detailTransaction)
  }

  onMouseEnterNoteField (transaction: AccountTransaction) {
    this.transactionWithVisibleNotes = transaction.uid
    this.showNoteOverlay(transaction)
  }

  onMouseLeaveNoteField (transaction: AccountTransaction) {
    this.transactionWithVisibleNotes = null
    this.hideNoteOverlay(transaction)
  }

  async showNoteOverlay (transaction: AccountTransaction) {
    await this.getTransactionNotes(transaction)
    if (transaction.uid !== this.transactionWithVisibleNotes) return
    if (this.$refs && this.$refs['note_overlay_panel_' + transaction.uid]) {
      (this.$refs['note_overlay_panel_' + transaction.uid] as any).show({ target: null })
    }
  }

  hideNoteOverlay (transaction: AccountTransaction) {
    if (this.$refs && this.$refs['note_overlay_panel_' + transaction.uid]) {
      (this.$refs['note_overlay_panel_' + transaction.uid] as any).hide()
    }
  }

  async onDetailTransactionChanged (transaction: AccountTransaction | undefined) {
    if (!transaction) {
      this.initDoubleClickBehavior()
      if (!this.selectedTransactions[0].id) return
      const response = await AccountTransaction
        .includes('account')
        .includes('transactionStatus')
        .includes('transactionCategory')
        .includes('investment')
        .includes('payee')
        .includes('recurrenceRule')
        .includes('parentTransaction')
        .includes('rootTransaction')
        .includes('updatedBy')
        .includes('createdBy')
        .selectExtra(['seriesEndDate'])
        .where({ id: this.selectedTransactions[0].id })
        .page(1)
        .per(1)
        .all()
      if (response && response.data && response.data[0]) {
        // TODO: expand this functionality so it updates all attributes
        this.selectedTransactions[0].noteCount = response.data[0].noteCount
        this.initDoubleClickBehavior()
      } else {
        this.getAccountTransactions()
        this.resetSelectedTransactions()
        this.showDetailDialog = false
        this.initDoubleClickBehavior()
      }
    } else {
      // TODO: expand this functionality so it updates all attributes
      this.selectedTransactions[0].noteCount = transaction.noteCount
      this.initDoubleClickBehavior()
    }
  }

  async onDetailTransactionDeleted () {
    this.showDetailDialog = false
    await this.getAccountTransactions()
    this.resetSelectedTransactions()
  }

  async onExceptionCreatedWithinDetailDialog (transaction: AccountTransaction | undefined) {
    if (transaction) {
      this.resetSelectedTransactions(transaction)

      // TODO: expand this functionality so it updates all attributes
      this.selectedTransactions[0].noteCount = transaction.noteCount
    }
  }

  transactionCategoryDisplay (category: TransactionCategory) {
    if (category && category.name) {
      return category.name
    }

    let uid = this.newTransaction.uid
    if (this.editingTransactions.length === 2) {
      uid = this.editingTransactions[1].uid
    }
    if (this.$refs && this.$refs['autocompleteCategory-' + uid]) {
      return (this.$refs['autocompleteCategory-' + uid] as any).inputTextValue
    }
  }

  payeeDisplay (payeeOption: Account|Payee, draftType = '') {
    if (payeeOption && payeeOption.name && payeeOption.type === 'Account') {
      if (draftType === 'adjustment') {
        return 'Account Reconciliation'
      } else if (draftType === 'initial_adjustment') {
        return 'Starting Balance'
      } else {
        return 'Transfer: ' + payeeOption.name
      }
    } else if (payeeOption && payeeOption.name) {
      return payeeOption.name
    }

    let uid = this.newTransaction.uid
    if (this.editingTransactions.length === 2) {
      uid = this.editingTransactions[1].uid
    }
    if (this.$refs && this.$refs['autocompletePayee-' + uid]) {
      return (this.$refs['autocompletePayee-' + uid] as any).inputTextValue
    }
  }

  repeatPatternValue (t: AccountTransaction) {
    let resultString = ''
    if (t.recurrenceRule) {
      resultString += t.recurrenceRule.displayName
    } else if (t.parentTransaction && t.parentTransaction.recurrenceRuleId) {
      resultString += this.recurrenceRules.filter(item => {
        if (!t.parentTransaction) return false
        return String(item.id) === String(t.parentTransaction.recurrenceRuleId)
      })[0].displayName
    }
    return resultString
  }

  repeatPatternDisplay (t: AccountTransaction) {
    let resultString = 'Repeats '

    resultString += this.repeatPatternValue(t)

    if (t.seriesEndDate) {
      resultString += '\n until '
      if (this && this.$options && this.$options.filters && this.$options.filters.appDate) {
        resultString += this.$options.filters.appDate(t.seriesEndDate)
      }
    }

    return resultString
  }

  isAdjustment (t: AccountTransaction) {
    return t.draftType === 'adjustment' || t.draftType === 'initial_adjustment'
  }

  isInitialAdjustment (t: AccountTransaction) {
    return t.draftType === 'initial_adjustment'
  }

  accountName (transaction: AccountTransaction) {
    return transaction.account ? transaction.account.name : ''
  }

  getRowClass (transaction: AccountTransaction) {
    let classString = ''
    if (this.reverseSelectedTransaction === transaction.uid) {
      classString += 'secondary-highlight '
    }
    if (transaction.draftType === 'adjustment') {
      classString += 'adjustment-row '
    }
    if (transaction.draftType === 'initial_adjustment') {
      classString += 'initial-adjustment-row '
    }
    if (this.readOnly) {
      classString += 'min-row-size'
    }
    return classString
  }

  inverseOf (amount: number) {
    if (!amount) return
    return -1 * amount
  }

  isForecastedToday (transaction: AccountTransaction) {
    if (!transaction) return false

    const transactionDate = dayjs(transaction.transactionDate as any)
    const today = dayjs()

    return transaction.type !== 'new' &&
      transaction.transactionStatus.name === 'Forecast' &&
      transactionDate.get('year') === today.get('year') &&
      transactionDate.get('month') === today.get('month') &&
      transactionDate.get('date') === today.get('date')
  }

  isForecastedBeforeToday (transaction: AccountTransaction) {
    if (!transaction) return false
    return transaction.type !== 'new' &&
      transaction.transactionStatus.name === 'Forecast' &&
      dayjs(transaction.transactionDate as any).diff(dayjs(), 'day') < 0
  }

  @Emit()
  computeRunningBalances () {
    this.forecastedBalances = []
    const appBalance = this.totalWorkingBalance
    let prevBalance = appBalance
    for (let i = 0; i < this.transactions.length; i++) {
      const t = this.transactions[i]
      const delta = (t.inflow || 0) * 1.0 - (t.outflow || 0) * 1.0
      const nextBalance = prevBalance + delta
      this.forecastedBalances.push(nextBalance)
      prevBalance = nextBalance
    }
    return this.forecastedBalances[this.forecastedBalances.length - 1] || appBalance
  }

  revalidateInflow (t: AccountTransaction) {
    const inflowPresent = this.computeInflowPresent(t)

    // prefer empty to $0.00 so placeholder is visible
    //  in at least one inflow/outflow input at all times
    if (inflowPresent) t.outflow = null
    if (!inflowPresent) t.inflow = null
  }

  revalidateOutflow (t: AccountTransaction) {
    const outflowPresent = this.computeOutflowPresent(t)

    // prefer empty to $0.00 so placeholder is visible
    //  in at least one inflow/outflow input at all times
    if (outflowPresent) t.inflow = null
    if (!outflowPresent) t.outflow = null
  }

  computeInflowPresent (t: AccountTransaction) {
    return t.inflow !== undefined && t.inflow !== null && t.inflow !== 0
  }

  computeOutflowPresent (t: AccountTransaction) {
    return t.outflow !== undefined && t.outflow !== null && t.outflow !== 0
  }

  initDoubleClickBehavior () {
    const rows = document.getElementsByClassName('p-selectable-row')
    for (let i = 0; i < rows.length; i++) {
      const openTransactionDetailDialog = this.openTransactionDetailDialog
      rows[i].addEventListener('dblclick', function () {
        openTransactionDetailDialog()
      }, false)
    }
  }

  determineTableOverflowBehavior () {
    if (this.containingView === 'forecast' || this.containingView === 'reports') return

    const tablePosition = this.calcTablePosition()
    const footerPosition = this.calcFooterPosition()
    if (!tablePosition || !footerPosition || !this.enableCreate) return

    const cutoffPoint = 30
    if (footerPosition - tablePosition > cutoffPoint) {
      (document as any).getElementById('account_transaction_table').classList.add('pop-out-row-elements')
    } else {
      (document as any).getElementById('account_transaction_table').classList.remove('pop-out-row-elements')
    }
  }

  calcFooterPosition () {
    if (!document || !document.getElementById('app_footer')) return null
    return (document as any).getElementById('app_footer').getBoundingClientRect().top || null
  }

  calcTablePosition () {
    if (!document || !document.getElementById('account_transaction_table')) return null
    return (document as any).getElementById('account_transaction_table').getBoundingClientRect().bottom || null
  }
}
