import ApplicationRecord from '@/models/application_record'

import {
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class AttachmentBlob extends ApplicationRecord {
  static jsonapiType = 'attachment_blobs'

  @Attr filename!: string
  @Attr byteSize!: number
}
