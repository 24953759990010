










import { Component, Prop, Vue } from 'vue-property-decorator'
import exportJSONToCSV from '@/utils/exportJSONToCSV'
import { AccountTransaction } from '@/models'
import Button from 'primevue/button'
Vue.component('Button', Button)

@Component
export default class AccountTransactionExporter extends Vue {
  @Prop() totalRecords!: number
  @Prop() repeatPatternValueFormatter!: Function
  @Prop() getPage!: Function
  @Prop() hiddenColumns!: string[]

  columns = [
    { name: 'Repeats', value: 'recurring' },
    { name: 'Until', value: 'recurring' },
    { name: 'Date', value: 'transactionDate' },
    { name: 'Account', value: 'accountName' },
    { name: 'Payee Name', value: 'payeeName' },
    { name: 'Payee Type', value: 'payeeName' },
    { name: 'Line Item', value: 'transactionCategory' },
    { name: 'Inflow', value: 'inflow' },
    { name: 'Outflow', value: 'outflow' },
    { name: 'Estimate', value: 'isEstimate' },
    { name: 'Equity Investment Name', value: 'equity' },
    { name: 'Equity Investment Type', value: 'equity' },
    { name: 'Status', value: 'transactionStatusName' },
    { name: 'Type', value: 'draftType' }
  ]

  exporting = false
  exportingProgress: number | null = null

  async exportCSV () {
    this.exporting = true
    this.exportingProgress = 0

    const transactions = await this.fetchTransactionsInBatches()
    const transactionsAsJson = this.formatJsonPayload(transactions!)
    exportJSONToCSV(transactionsAsJson, 'account_transactions.csv')

    this.exportingProgress = null
    this.exporting = false
  }

  async fetchTransactionsInBatches () {
    const BATCH_SIZE = 50
    const TOTAL_COUNT = this.totalRecords
    const PAGE_SIZE = TOTAL_COUNT > BATCH_SIZE ? BATCH_SIZE : TOTAL_COUNT
    const TOTAL_PAGES = Math.ceil(TOTAL_COUNT / PAGE_SIZE)

    let page = 1

    let transactions: AccountTransaction[] = []
    while (page <= TOTAL_PAGES) {
      this.exportingProgress = Math.floor((page / TOTAL_PAGES) * 100)
      const response = (await this.getPage(page, PAGE_SIZE))
      if (!response) return
      const pageTransactions = response.data
      transactions = transactions.concat(pageTransactions)
      page++
    }
    return transactions
  }

  formatJsonPayload (transactions: AccountTransaction[]) {
    return transactions.map(transaction => {
      if (!this.$options.filters) return {}
      const fullTransaction = {
        Repeats: this.repeatPatternValueFormatter(transaction),
        Until: this.$options.filters.appDate(transaction.seriesEndDate),
        Date: this.$options.filters.appDate(transaction.transactionDate),
        Account: transaction.account ? transaction.account.name : null,
        'Payee Name': transaction.payee ? transaction.payee.name : null,
        'Payee Type': transaction.payeeType,
        'Line Item': transaction.transactionCategory ? transaction.transactionCategory.name : null,
        Inflow: this.$options.filters.appCurrency(transaction.inflow),
        Outflow: this.$options.filters.appCurrency(transaction.outflow ? -1.0 * transaction.outflow : null),
        Estimate: transaction.isEstimate || null,
        'Equity Investment Name': transaction.investment ? transaction.investment.name : null,
        'Equity Investment Type': transaction.investment ? this.$options.filters.deSnake(transaction.investment.investmentType) : null,
        Status: transaction.transactionStatus ? transaction.transactionStatus.name : null,
        Type: this.$options.filters.appDraftType(transaction.draftType)
      }
      return this.filterHiddenColumns(fullTransaction)
    })
  }

  filterHiddenColumns (transaction: any) {
    const visibleColumnValues = this.columns.map(item => item.value).filter(item => {
      return !this.hiddenColumns.includes(item)
    })
    const visibleColumnNames = this.columns.filter(item => {
      return visibleColumnValues.includes(item.value)
    }).map(item => item.name)

    // filter out hidden columns/keys from transaction payload
    Object.keys(transaction)
      .filter(key => !visibleColumnNames.includes(key))
      .forEach(key => delete transaction[key])

    return transaction
  }
}
