import ApplicationRecord from '@/models/application_record'
import UserModel from '@/models/user'
import TenantModel from '@/models/tenant'
import MemberPreferenceModel from '@/models/member_preference'

import {
  Model,
  Attr,
  BelongsTo,
  HasOne
} from 'spraypaint'

@Model()
class User extends UserModel {}

@Model()
class Tenant extends TenantModel {}

@Model()
class MemberPreference extends MemberPreferenceModel {}

@Model()
export default class Membership extends ApplicationRecord {
  static jsonapiType = 'memberships'

  @Attr userId!: string
  @Attr tenantId!: string

  @BelongsTo() user!: User
  @BelongsTo() tenant!: Tenant
  @HasOne() memberPreference!: MemberPreference
}
