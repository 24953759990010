



















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import dayjs from 'dayjs'

import Version from '@/models/version'
import User from '@/models/user'
import Membership from '@/models/membership'
import Account from '@/models/account'
import Payee from '@/models/payee'
import Investment from '@/models/investment'
import Company from '@/models/company'
import Project from '@/models/project'
import TaskStatus from '@/models/task_status'
import TransactionStatus from '@/models/transaction_status'
import TransactionCategory from '@/models/transaction_category'
import RecurrenceRule from '@/models/recurrence_rule'
import Tenant from '@/models/tenant'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import VersionCard from './components/VersionCard'
import VersionActionContent from './components/VersionActionContent.vue'
import VersionDetailContent from './components/VersionDetailContent.vue'

Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('Calendar', Calendar)
Vue.component('Dropdown', Dropdown)
Vue.component('VersionCard', VersionCard)
Vue.component('VersionActionContent', VersionActionContent)
Vue.component('VersionDetailContent', VersionDetailContent)

interface PrimeVuePageEvent {
  page: number;
  rows: number;
}

interface DateShortcut {
  name: string;
  value: (string | null)[] | null;
}

const Auth = namespace('auth')

@Component
export default class AppVersionsTable extends Vue {
  @Prop({ default: () => { return true } }) showFilters!: boolean
  @Prop({ default: () => { return true } }) enablePagination!: boolean
  @Prop({ default: () => { return '' } }) providedItemType!: string
  @Prop({ default: () => { return '' } }) providedItemId!: string
  @Prop({ default: () => { return '200' } }) scrollHeightDiff!: string

  @Auth.Getter currentPermissions!: string

  loading = true
  versions: Version[] = []
  users: User[] = []

  usersHash: { [key: string]: string } = {}
  membershipsHash: { [key: string]: string } = {}
  accountsHash: { [key: string]: string } = {}
  payeesHash: { [key: string]: string } = {}
  investmentsHash: { [key: string]: string } = {}
  companiesHash: { [key: string]: string } = {}
  projectsHash: { [key: string]: string } = {}
  taskStatusesHash: { [key: string]: string } = {}
  transactionStatusesHash: { [key: string]: string } = {}
  transactionCategoriesHash: { [key: string]: string } = {}
  recurrenceRulesHash: { [key: string]: string } = {}
  tenantsHash: { [key: string]: string } = {}

  investmentsLookup: { [key: string]: Investment } = {}

  page = 1
  pageSize = this.enablePagination ? 100 : 100000
  totalRecords = 0
  expandedRows: Version[] = []

  itemTypeParam: string | null = null
  itemIdParam: string | null = null
  whodunnitParam: string | null = null
  createdAtParam: (DateShortcut | null)[] = [
    null,
    null
  ]

  enableRead = false

  showCustomDateRangePicker = false
  dateShortcuts: (DateShortcut | null)[] = [
    {
      name: 'All Dates',
      value: [
        null,
        null
      ]
    }, {
      name: 'Today',
      value: [
        dayjs().startOf('day').format('YYYY-MM-DD'),
        dayjs().endOf('day').format('YYYY-MM-DD')
      ]
    }, {
      name: 'Yesterday',
      value: [
        dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
      ]
    }, {
      name: 'This Week',
      value: [
        dayjs().startOf('week').format('YYYY-MM-DD'),
        dayjs().endOf('week').format('YYYY-MM-DD')
      ]
    }, {
      name: 'This Month',
      value: [
        dayjs().startOf('month').format('YYYY-MM-DD'),
        dayjs().endOf('month').format('YYYY-MM-DD')
      ]
    }, {
      name: 'This Year',
      value: [
        dayjs().startOf('year').format('YYYY-MM-DD'),
        dayjs().endOf('year').format('YYYY-MM-DD')
      ]
    },
    {
      name: 'Select Dates...',
      value: []
    }
  ]

  get userOptions () {
    return [new User({
      id: null,
      name: 'All Users'
    })].concat(this.users).filter(user => {
      return user.firstName !== 'System'
    })
  }

  get formattedCreatedAtParam () {
    const formattedCreatedAtParam: { lte: string | null; gte: string | null } = {
      gte: null,
      lte: null
    }
    if (this.createdAtParam[0]) {
      formattedCreatedAtParam.gte = dayjs(this.createdAtParam[0] as unknown as string).startOf('day').format()
    }
    if (this.createdAtParam[1]) {
      formattedCreatedAtParam.lte = dayjs(this.createdAtParam[1] as unknown as string).endOf('day').format()
    }
    return formattedCreatedAtParam
  }

  get objectFilterIsActive () {
    return this.itemTypeParam && this.itemIdParam
  }

  async mounted () {
    this.getCurrentPermissions()
    await Promise.all([
      this.getUsers(),
      this.getAccounts(),
      this.getPayees(),
      this.getInvestments(),
      this.getCompanies(),
      this.getProjects(),
      this.getTaskStatuses(),
      this.getTransactionStatuses(),
      this.getTransactionCategories(),
      this.getRecurrenceRules(),
      this.getTenants()
    ])
    this.getMemberships()
    this.getVersions()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableRead = permissions.includes('read_version')
  }

  async getVersions () {
    if (!this.enableRead) return

    this.loading = true

    if (this.providedItemType || this.providedItemId) {
      if (!(this.providedItemType && this.providedItemId)) {
        this.loading = false
        return
      }
      this.itemTypeParam = this.providedItemType
      this.itemIdParam = this.providedItemId
    }

    const whereFilter: any = {
      // ignore token authentication noise
      tokenChange: false,
      createdAt: this.formattedCreatedAtParam,
      relatedItem: null,
      relevantUser: null
    }

    if (this.objectFilterIsActive && this.itemTypeParam === 'AccountTransaction') {
      // include audits related to associated objects
      whereFilter.relatedItem = {
        itemId: this.itemIdParam,
        itemType: this.itemTypeParam
      }
    } else {
      // do not include associated objects in results
      whereFilter.itemType = this.itemTypeParam
      whereFilter.itemId = this.itemIdParam
    }

    if (this.whodunnitParam) {
      // include sign in events in addition to standard user events
      whereFilter.relevantUser = this.whodunnitParam
    }

    const response = await Version
      .where(whereFilter)
      .order({ createdAt: 'desc' })
      .page(this.page)
      .per(this.pageSize)
      .stats({ total: 'count' })
      .all()

    this.versions = response.data
    this.totalRecords = response.meta.stats.total.count

    this.expandedRows = []
    this.loading = false
  }

  async onPage (event: PrimeVuePageEvent) {
    this.loading = true
    this.page = event.page + 1
    this.pageSize = event.rows
    this.getVersions()
  }

  async getUsers () {
    const usersHash: { [key: string]: string } = {}
    this.users = (
      await User.per(100000).order('name').all()
    ).data
    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i]
      usersHash[user.id as string] = user.firstName
    }
    this.usersHash = usersHash
  }

  async getMemberships () {
    const membershipsHash: { [key: string]: string } = {}
    const memberships = (
      await Membership.per(100000).all()
    ).data
    for (let i = 0; i < memberships.length; i++) {
      const membership = memberships[i]
      membershipsHash[membership.id as string] = this.usersHash[membership.userId]
    }
    this.membershipsHash = membershipsHash
  }

  async getAccounts () {
    const accountsHash: { [key: string]: string } = {}
    const accounts = (
      await Account.per(100000).all()
    ).data
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i]
      accountsHash[account.id as string] = account.name
    }
    this.accountsHash = accountsHash
  }

  async getPayees () {
    const payeesHash: { [key: string]: string } = {}
    const payees = (
      await Payee.per(100000).all()
    ).data
    for (let i = 0; i < payees.length; i++) {
      const payee = payees[i]
      payeesHash[payee.id as string] = payee.name
    }
    this.payeesHash = payeesHash
  }

  async getTenants () {
    const tenantsHash: { [key: string]: string } = {}
    const tenants = (
      await Tenant.per(100000).all()
    ).data
    for (let i = 0; i < tenants.length; i++) {
      const tenant = tenants[i]
      tenantsHash[tenant.id as string] = tenant.name
    }
    this.tenantsHash = tenantsHash
  }

  async getInvestments () {
    const investments = (
      await Investment.per(100000).all()
    ).data

    const investmentsHash: { [key: string]: string } = {}
    for (let i = 0; i < investments.length; i++) {
      const investment = investments[i]
      investmentsHash[investment.id as string] = investment.name
    }
    this.investmentsHash = investmentsHash

    const investmentsLookup: { [key: string]: Investment } = {}
    for (let i = 0; i < investments.length; i++) {
      const investment = investments[i]
      investmentsLookup[investment.id as string] = investment
    }
    this.investmentsLookup = investmentsLookup
  }

  async getCompanies () {
    const companies = (
      await Company.per(100000).all()
    ).data

    const companiesHash: { [key: string]: string } = {}
    for (let i = 0; i < companies.length; i++) {
      const company = companies[i]
      companiesHash[company.id as string] = company.name
    }
    this.companiesHash = companiesHash
  }

  async getProjects () {
    const projects = (
      await Project.per(100000).all()
    ).data

    const projectsHash: { [key: string]: string } = {}
    for (let i = 0; i < projects.length; i++) {
      const project = projects[i]
      projectsHash[project.id as string] = project.name
    }
    this.projectsHash = projectsHash
  }

  async getTaskStatuses () {
    const taskStatusesHash: { [key: string]: string } = {}
    const taskStatuses = (
      await TaskStatus.per(100000).all()
    ).data
    for (let i = 0; i < taskStatuses.length; i++) {
      const taskStatus = taskStatuses[i]
      taskStatusesHash[taskStatus.id as string] = taskStatus.name
    }
    this.taskStatusesHash = taskStatusesHash
  }

  async getTransactionStatuses () {
    const transactionStatusesHash: { [key: string]: string } = {}
    const transactionStatuses = (
      await TransactionStatus.per(100000).all()
    ).data
    for (let i = 0; i < transactionStatuses.length; i++) {
      const transactionStatus = transactionStatuses[i]
      transactionStatusesHash[transactionStatus.id as string] = transactionStatus.name
    }
    this.transactionStatusesHash = transactionStatusesHash
  }

  async getTransactionCategories () {
    const transactionCategoriesHash: { [key: string]: string } = {}
    const transactionCategories = (
      await TransactionCategory.per(100000).all()
    ).data
    for (let i = 0; i < transactionCategories.length; i++) {
      const transactionStatus = transactionCategories[i]
      transactionCategoriesHash[transactionStatus.id as string] = transactionStatus.name
    }
    this.transactionCategoriesHash = transactionCategoriesHash
  }

  async getRecurrenceRules () {
    const recurrenceRulesHash: { [key: string]: string } = {}
    const recurrenceRules = (
      await RecurrenceRule.per(100000).all()
    ).data
    for (let i = 0; i < recurrenceRules.length; i++) {
      const recurrenceRule = recurrenceRules[i]
      if (!this.$options.filters) continue
      recurrenceRulesHash[recurrenceRule.id as string] = this.$options.filters.deSnake(recurrenceRule.name)
    }
    this.recurrenceRulesHash = recurrenceRulesHash
  }

  filterByObject (item: { [key: string]: string }) {
    this.itemTypeParam = item.type
    this.itemIdParam = item.id
    this.getVersions()
  }

  clearObjectFilter () {
    this.itemTypeParam = null
    this.itemIdParam = null
    this.getVersions()
  }

  isSignInEvent (version: Version) {
    const objectChanges: any = version.objectChanges
    return version.event === 'update' &&
      version.itemType === 'User' &&
      objectChanges &&
      objectChanges.sign_in_count &&
      objectChanges.sign_in_count[0] + 1 === objectChanges.sign_in_count[1]
  }

  isToday (date: Date) {
    const today = dayjs()
    return dayjs(date).isSame(today, 'day')
  }

  isYesterday (date: Date) {
    const yesterday = dayjs().subtract(1, 'day')
    return dayjs(date).isSame(yesterday, 'day')
  }

  onChangeSelectedDates () {
    const val = this.createdAtParam

    if (!val || val.length === 0) {
      this.showCustomDateRangePicker = true
      Vue.nextTick(function () {
        const customRangePicker = document.getElementById('custom_range_picker')
        if (customRangePicker) {
          customRangePicker.focus()
        }
      })
    } else if (val.length === 2 && val[1] !== null && val[2] !== null) {
      this.showCustomDateRangePicker = false
      this.getVersions()
    } else {
      this.getVersions()
    }
  }

  itemTypesLookup (version: Version, itemType = '') {
    const versionItemType = version ? version.itemType : itemType
    if (versionItemType === 'AccountTransaction') {
      return 'Transaction'
    }
    if (versionItemType === 'MemberAccessible') {
      return 'Access'
    }
    if (versionItemType === 'InvestmentTransaction') {
      if (version) {
        const contributionAmount = (version.objectChanges as any).contribution_amount
          ? ((version.objectChanges as any).contribution_amount[1] || (version.objectChanges as any).contribution_amount[0])
          : (version.object ? (version.object as any).contribution_amount : '')
        const distributionAmount = (version.objectChanges as any).distribution_amount
          ? ((version.objectChanges as any).distribution_amount[1] || (version.objectChanges as any).distribution_amount[0])
          : (version.object ? (version.object as any).distribution_amount : '')
        if (contributionAmount && Number(contributionAmount) > 0) {
          return 'Contribution'
        } else if (distributionAmount && Number(distributionAmount) > 0) {
          return 'Distribution'
        }
      }
      return 'Contribution/Distribution'
    }
    return this.$options.filters ? this.$options.filters.splitOnCaps(versionItemType) : versionItemType
  }
}
