























































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'

import {
  AccountTransaction,
  RecurrenceRule
} from '@/models'

import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Card from 'primevue/card'
import TransactionDetailSection from './TransactionDetailSection.vue'

Vue.component('Dialog', Dialog)
Vue.component('Button', Button)
Vue.component('Card', Card)
Vue.component('TransactionDetailSection', TransactionDetailSection)

@Component
export default class RecurringTransactionSaveDialog extends Vue {
  @Prop() transaction!: AccountTransaction
  @Prop() recurrenceRules!: RecurrenceRule[]
  @Prop() show!: boolean

  showDialog = false
  showPreviewContent = false
  transactionPreview = new AccountTransaction()
  nextOccurrence: AccountTransaction = new AccountTransaction()

  async generateTransactionPreview () {
    const transaction = JSON.parse(JSON.stringify(this.transaction))
    transaction.transactionDate = await this.nextOccurrenceDate()
    this.transactionPreview = transaction
    this.getNextOccurrence()
  }

  async nextOccurrenceDate () {
    let recurrenceRuleId = this.transaction.recurrenceRuleId
    if (this.transaction.type === 'exception') {
      if (!this.transaction.parentTransactionId) return
      const parentTransaction = (await AccountTransaction.find(this.transaction.parentTransactionId)).data
      recurrenceRuleId = parentTransaction.recurrenceRuleId
    }
    const recurrenceRule = this.recurrenceRules.filter(rule => {
      return String(rule.id) === String(recurrenceRuleId)
    })[0]
    if (!recurrenceRule) return null

    const currentDate = this.transaction.transactionDate!
    switch (recurrenceRule.name) {
      case 'weekly':
        return dayjs(currentDate).add(1, 'week').format('YYYY-MM-DD')
      case 'every_other_week':
        return dayjs(currentDate).add(2, 'weeks').format('YYYY-MM-DD')
      case 'monthly':
        return dayjs(currentDate).add(1, 'month').format('YYYY-MM-DD')
      case 'every_other_month':
        return dayjs(currentDate).add(2, 'months').format('YYYY-MM-DD')
      case 'quarterly':
        return dayjs(currentDate).add(3, 'months').format('YYYY-MM-DD')
      case 'twice_a_year':
        return dayjs(currentDate).add(6, 'months').format('YYYY-MM-DD')
      case 'yearly':
        return dayjs(currentDate).add(1, 'year').format('YYYY-MM-DD')
      default:
        return null
    }
  }

  @Watch('show')
  onShowChanged (val: boolean) {
    this.showDialog = val
    this.showPreviewContent = false
    if (this.showDialog) this.generateTransactionPreview()
  }

  async getNextOccurrence () {
    this.nextOccurrence = (await AccountTransaction
      .includes('account')
      .includes('transactionStatus')
      .includes('transactionCategory')
      .includes('investment')
      .includes('payee')
      .includes('recurrenceRule')
      .includes('parentTransaction')
      .includes('rootTransaction')
      .includes('updatedBy')
      .includes('createdBy')
      .selectExtra(['seriesEndDate'])
      .where({
        seriesId: this.transaction.seriesId,
        transactionDate: {
          gte: dayjs(this.transaction.transactionDate!).add(1, 'day').format('YYYY-MM-DD'),
          lte: dayjs().add(5, 'years').format('YYYY-MM-DD')
        }
      })
      .page(1)
      .per(1)
      .all()
    ).data[0]
  }

  @Emit()
  hide () { return null }

  @Emit()
  saveSingleTransaction () { return null }

  @Emit()
  saveAllFutureTransactions () { return null }

  @Emit()
  cancel () { return null }
}
