import ApplicationRecord from '@/models/application_record'
import { Tenant } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
export default class User extends ApplicationRecord {
  static jsonapiType = 'users'

  @Attr name!: string
  @Attr firstName!: string
  @Attr lastName!: string
  @Attr email!: string
  @Attr active!: boolean
  @Attr currentTenantId!: string
  @Attr colorCode!: string

  @Attr({ persist: false }) password!: string
  @Attr({ persist: false }) passwordConfirmation!: string

  @BelongsTo() currentTenant!: Tenant

  get initials (): string {
    const firstInitial = this.firstName ? this.firstName[0] : ''
    const lastInitial = this.lastName ? this.lastName[0] : ''
    return firstInitial + lastInitial
  }

  get fullName (): string {
    return [this.firstName, this.lastName].join(' ')
  }
}
