import ApplicationRecord from '@/models/application_record'
import capitalize from '@/filters/capitalize'

import {
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class RecurrenceRule extends ApplicationRecord {
  static jsonapiType = 'recurrence_rules'

  @Attr name!: string

  get displayName (): string {
    return capitalize(this.name.split('_').join(' ')) || ''
  }
}
