import ApplicationRecord from '@/models/application_record'
import { Membership, Permission } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
export default class MemberPermission extends ApplicationRecord {
  static jsonapiType = 'member_permissions'

  @Attr memberId!: string
  @Attr permissionId!: string

  @BelongsTo() member!: Membership
  @BelongsTo() permission!: Permission
}
