



























































import { Component, Prop, Vue } from 'vue-property-decorator'

import Version from '@/models/version'

@Component
export default class TaskVersionCardContent extends Vue {
  @Prop() version!: Version
  @Prop() projectsHash!: { [key: string]: string }
  @Prop() taskStatusesHash!: { [key: string]: string }
  @Prop() fieldValueMethod!: Function
  @Prop() fieldValueAfterMethod!: Function
  @Prop() fieldValueChangedMethod!: Function
}
