
































































































































































































































































































































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'

import dayjs from 'dayjs'

import Account from '@/models/account'
import Payee from '@/models/payee'
import Investment from '@/models/investment'
import AccountTransaction from '@/models/account_transaction'
import TransactionStatus from '@/models/transaction_status'
import TransactionCategory from '@/models/transaction_category'

import AppInputCurrency from '@/components/AppInputCurrency'

import FullCalendar from 'primevue/fullcalendar'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import Listbox from 'primevue/listbox'
import SelectButton from 'primevue/selectbutton'
import OverlayPanel from 'primevue/overlaypanel'
import Sidebar from 'primevue/sidebar'
import Dialog from 'primevue/dialog'

Vue.component('AppInputCurrency', AppInputCurrency)

Vue.component('Button', Button)
Vue.component('Calendar', Calendar)
Vue.component('FullCalendar', FullCalendar)
Vue.component('Dropdown', Dropdown)
Vue.component('Listbox', Listbox)
Vue.component('SelectButton', SelectButton)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('Sidebar', Sidebar)
Vue.component('Dialog', Dialog)

interface TransactionDateShortcut {
  name: string;
  value: (string | null)[] | null;
}

@Component
export default class CashFlowSidebar extends Vue {
  @Prop() accounts!: Account[]
  @Prop() investments!: Investment[]
  @Prop() transactionStatuses!: TransactionStatus[]
  @Prop() transactionCategories!: TransactionCategory[]
  @Prop() payees!: Payee[]
  @Prop() draftTypes!: string[]
  @Prop() accountParam!: string | null
  @Prop() payeeParam!: Account | Payee | null
  @Prop() investmentParam!: string | null
  @Prop() draftTypeParam!: string | null
  @Prop() recurringParam!: boolean | null
  @Prop() estimateParam!: boolean | null
  @Prop() transactionCategoryParam!: string | null
  @Prop() amountParam!: string | number | null
  @Prop() transactionStatusParam!: (string | undefined)[]
  @Prop() transactionDateParam!: (string | null)[] | null
  @Prop() accountSidebarItems!: Account[]
  @Prop() loadingTransactions!: boolean
  @Prop() permissions!: any

  @Emit()
  paramChanged (key: string | null = null, newValue: any = null) {
    return [key, newValue]
  }

  @Watch('accountParam')
  onAccountParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('accountParam', newValue)
  }

  @Watch('payeeParam')
  onPayeeParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('payeeParam', newValue)
  }

  @Watch('investmentParam')
  onInvestmentParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('investmentParam', newValue)
  }

  @Watch('draftTypeParam')
  onDraftTypeParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('draftTypeParam', newValue)
  }

  @Watch('recurringParam')
  onRecurringParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('recurringParam', newValue)
  }

  @Watch('estimateParam')
  onEstimateParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('estimateParam', newValue)
  }

  @Watch('transactionCategoryParam')
  onTransactionCategoryParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('transactionCategoryParam', newValue)
  }

  @Watch('amountParam')
  onAmountParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('amountParam', newValue)
  }

  @Watch('transactionStatusParam')
  onTransactionStatusParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('transactionStatusParam', newValue)
  }

  @Watch('transactionDateParam')
  onTransactionDateParamChanged (newValue: any) {
    if (this.triggeredClearAll) return
    this.paramChanged('transactionDateParam', newValue)
  }

  @Watch('triggeredClearAll')
  onClearAll (newValue: any) {
    if (!newValue) this.paramChanged()
  }

  loadingTransactionDates = true
  triggeredClearAll = false
  showCustomDateRangePicker = false
  showCustomDateUpToPicker = false
  transactionDateShortcuts: TransactionDateShortcut[] = [
    {
      name: 'Default',
      value: [
        null,
        dayjs().add(1, 'month').format('YYYY-MM-DD')
      ]
    }, {
      name: 'This Week',
      value: [
        dayjs().startOf('week').format('YYYY-MM-DD'),
        dayjs().endOf('week').format('YYYY-MM-DD')
      ]
    }, {
      name: 'This Month',
      value: [
        dayjs().startOf('month').format('YYYY-MM-DD'),
        dayjs().endOf('month').format('YYYY-MM-DD')
      ]
    }, {
      name: 'This Year',
      value: [
        dayjs().startOf('year').format('YYYY-MM-DD'),
        dayjs().endOf('year').format('YYYY-MM-DD')
      ]
    }, {
      name: 'All Dates Up To...',
      value: [null]
    }, {
      name: 'Select Dates...',
      value: []
    }
  ]

  // calendar config
  transactionDates: Date[] = []
  fullCalendarEvents: any[] = []
  showFullTransactionCalendar = false
  calendarIsMinimized = true
  disableTransactionCalendarBack = false
  disableTransactionCalendarForward = false
  noTransactionsInMonth = false
  selectedCalendarMonth = dayjs().month()
  selectedCalendarYear = dayjs().year()

  showClosedAccounts = false

  get filteredAccounts () {
    return this.accountSidebarItems.filter(item => {
      return this.showClosedAccounts || (!this.showClosedAccounts && item.active)
    })
  }

  toggleClosedAccounts () {
    this.showClosedAccounts = !this.showClosedAccounts
  }

  get payeeOptions () {
    let options: (Account|Payee)[] = this.accounts.filter(item => {
      return item.id
    })
    options = options.concat(this.payees)
    return options
  }

  get activeAdditionalFilters () {
    const result: string[] = []
    if (this.payeeParam) result.push('payee')
    if (this.investmentParam) result.push('investment')
    if (this.draftTypeParam !== null) result.push('draftType')
    if (this.recurringParam !== null) result.push('recurring')
    if (this.estimateParam !== null) result.push('estimate')
    if (!(this.transactionCategoryParam === null || this.transactionCategoryParam === '')) result.push('transactionCategory')
    if (this.amountParam !== null) result.push('amount')
    return result
  }

  get fullCalendarOptions () {
    return {
      plugins: [dayGridPlugin, interactionPlugin],
      initialDate: dayjs(
        String(this.selectedCalendarYear === undefined ? dayjs().year() : this.selectedCalendarYear) +
        '-' +
        String((this.selectedCalendarMonth === undefined ? dayjs().month() : this.selectedCalendarMonth) + 1) +
        '-01'
      ).format('YYYY-MM-DD'),
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: null
      },
      defaultView: 'dayGridMonth',
      height: 'parent',
      dayMaxEventRows: true,
      showNonCurrentDates: false
    }
  }

  async getTransactionDates (month: number, year: number) {
    if (!this.permissions.enableReadAccountTransaction) return

    if (month === undefined) month = dayjs().month()
    if (year === undefined) year = dayjs().year()

    this.loadingTransactionDates = true
    const whereFilter = {
      accountId: '',
      payeeType: this.payeeParam ? this.payeeParam.type : '',
      payeeId: this.payeeParam ? this.payeeParam.id : '',
      investmentId: this.investmentParam,
      draftType: this.draftTypeParam,
      isRecurrence: this.recurringParam,
      isEstimate: this.estimateParam,
      transactionCategoryId: this.transactionCategoryParam,
      absoluteAmount: { eq: this.amountParam },
      transactionDate: this.formatTransactionDateParam(month, year),
      transactionStatusId: this.transactionStatusParam
    }

    if (this.accountParam && !(this.accountParam.length === 1 && this.accountParam[0] === null)) {
      whereFilter.accountId = this.accountParam
    }

    const response = await AccountTransaction
      .where(whereFilter)
      .select([
        'transactionDate',
        'payeeType',
        'payeeId',
        'transactionCategoryId',
        'inflow',
        'outflow',
        'draftType',
        'transactionStatusId'
      ])
      .page(1)
      .per(999999)
      .all()

    const transactions = response.data
    const transactionDates = transactions.map(item => new Date(dayjs(item.transactionDate as any) as any))

    if (transactionDates && transactionDates.length > 0) {
      this.transactionDates = transactionDates
      this.noTransactionsInMonth = false
    } else {
      // "hack" to ensure selected month is the current start date filter month,
      // even when there are no transactions in the month for the selected filter params
      this.transactionDates = [new Date(year, month, 1)]
      this.noTransactionsInMonth = true
    }

    this.loadingTransactionDates = false
    this.generateFullCalendarEvents(transactions)
    return transactionDates
  }

  generateFullCalendarEvents (transactions: AccountTransaction[]) {
    const bgColors = [
      'white',
      '#027abc',
      '#5e8f32'
    ]
    const textColors = [
      'black',
      'white',
      'white'
    ]
    const borderColors = [
      '#027abc',
      '#027abc',
      '#5e8f32'
    ]

    this.fullCalendarEvents = transactions.map((item) => {
      const itemPayeeOptions = this.payeeOptions.filter((p) => {
        return String(p.id) === String(item.payeeId) && p.type === item.payeeType
      })
      const itemPayee = (itemPayeeOptions && itemPayeeOptions.length > 0) ? this.payeeCalendarDisplay(itemPayeeOptions[0]) : (item.transactionCategory ? item.transactionCategory.name : null)

      const itemAmount = item.inflow
        ? this.$options.filters!.appCurrency(item.inflow)
        : (item.outflow ? this.$options.filters!.appCurrency(-1 * item.outflow) : '$0.00')
      const itemTitle = item.draftType === 'adjustment'
        ? (itemAmount !== '$0.00' ? 'Reconciled w/ ' + itemAmount + ' Adjustment' : 'Reconciled')
        : (itemAmount + (item.inflow ? ' from ' : ' to ') + itemPayee)
      const itemBackgroundColor = item.draftType === 'adjustment' ? '#e4dff4' : bgColors[Number(item.transactionStatusId) - 1]
      const itemTextColor = item.draftType === 'adjustment' ? 'black' : textColors[Number(item.transactionStatusId) - 1]
      const itemBorderColor = item.draftType === 'adjustment' ? 'black' : borderColors[Number(item.transactionStatusId) - 1]

      return {
        id: item.id,
        title: itemTitle,
        start: item.transactionDate,
        backgroundColor: itemBackgroundColor,
        textColor: itemTextColor,
        borderColor: itemBorderColor,
        classNames: ['full-calendar-event-text']
      }
    })
  }

  formatTransactionDateParam (month: number, year: number) {
    let startDate = dayjs(String(year) + '-' + String(month + 1) + '-01')
    let endDate = startDate.endOf('month')

    const filterStartDate = dayjs(this.transactionDateParam![0] as any)
    const filterEndDate = dayjs(this.transactionDateParam![1] as any)
    if (filterStartDate > startDate) startDate = filterStartDate
    if (filterEndDate < endDate) endDate = filterEndDate

    const formattedTransactionDateParam: { lte: string | null; gte: string | null } = {
      lte: endDate.format('YYYY-MM-DD'),
      gte: startDate.format('YYYY-MM-DD')
    }
    return formattedTransactionDateParam
  }

  async onChangeTransactionCalendarMonth (event: any) {
    this.selectedCalendarMonth = event.month
    this.selectedCalendarYear = event.year
    this.checkToDisableCalendarNavigationBasedOnFilterDates(event.month, event.year)
    this.checkToDisableFullCalendarNavigationBasedOnMiniCalendar()
    this.getTransactionDates(event.month, event.year)
  }

  onChangeSelectedDates () {
    this.resetTransactionDateShortcutOptions()

    const val = this.transactionDateParam

    if (!val || val.length === 0) {
      this.showCustomDateRangePicker = true
      Vue.nextTick(function () {
        const customRangePicker = document.getElementById('custom_range_picker')
        if (customRangePicker) {
          customRangePicker.focus()
        }
      })
    } else if (val.length === 1) {
      this.showCustomDateUpToPicker = true
      Vue.nextTick(function () {
        const customUpToPicker = document.getElementById('custom_up_to_picker')
        if (customUpToPicker) {
          customUpToPicker.focus()
        }
      })
    } else if (val.length === 2 && val[1] !== null) {
      this.showCustomDateRangePicker = false
      this.showCustomDateUpToPicker = false
    }
  }

  onAmountParamChange () {
    if (!this.amountParam || this.amountParam === 0) {
      this.amountParam = null
    }
  }

  showFullCalendar () {
    this.showFullTransactionCalendar = true
    this.checkToDisableFullCalendarNavigationBasedOnMiniCalendar()
  }

  minimizeCalendar () {
    this.calendarIsMinimized = true
  }

  maximizeCalendar () {
    this.calendarIsMinimized = false
  }

  toggleFilterOverlay (event: any) {
    if (this.$refs && this.$refs.filter_overlay_panel) {
      (this.$refs.filter_overlay_panel as any).toggle(event)
    }
  }

  payeeDisplay (payeeOption: Account|Payee) {
    if (payeeOption && payeeOption.name && payeeOption.type === 'Account') {
      return '[Account] ' + payeeOption.name
    } else if (payeeOption && payeeOption.name) {
      return payeeOption.name
    }
  }

  payeeCalendarDisplay (payeeOption: Account|Payee) {
    if (payeeOption && payeeOption.name) {
      return payeeOption.name
    }
  }

  payeeValue (payeeOption: Account|Payee) {
    if (payeeOption && payeeOption.name && payeeOption.type === 'Account') {
      return { type: 'Account', id: payeeOption.id }
    } else if (payeeOption && payeeOption.name) {
      return { type: 'Payee', id: payeeOption.id }
    }
  }

  resetTransactionDateShortcutOptions () {
    this.transactionDateShortcuts.filter(item => {
      return item.name === 'All Dates Up To...'
    })[0].value = [null]
  }

  clearFilters () {
    this.triggeredClearAll = true

    this.payeeParam = null
    this.transactionCategoryParam = null
    this.amountParam = null
    this.recurringParam = null
    this.estimateParam = null
    this.investmentParam = null
    this.draftTypeParam = null

    Vue.nextTick(() => {
      this.triggeredClearAll = false
    })
  }

  checkToDisableCalendarNavigationBasedOnFilterDates (calendarMonth: number = dayjs().month(), calendarYear: number = dayjs().year()) {
    let disableTransactionCalendarForward = false
    let disableTransactionCalendarBack = false

    const filterStartDate = dayjs(this.transactionDateParam![0] as any)
    const calendarStartDate = dayjs(String(calendarYear) + '-' + String(calendarMonth + 1) + '-01')
    if (calendarStartDate.diff(filterStartDate, 'day') <= 0) {
      disableTransactionCalendarBack = true
    }

    const filterEndDate = dayjs(this.transactionDateParam![1] as any)
    const calendarEndDate = calendarStartDate.endOf('month')
    if (calendarEndDate.diff(filterEndDate, 'day') >= 0) {
      disableTransactionCalendarForward = true
    }

    this.disableTransactionCalendarForward = disableTransactionCalendarForward
    this.disableTransactionCalendarBack = disableTransactionCalendarBack
  }

  checkToDisableFullCalendarNavigationBasedOnMiniCalendar () {
    // hacky way to hook into the fullcalendar prev/next events since their api method (getApi) is not working as advertised
    // it works by detecting a prev/next click on the fullcalendar and triggering an equivalent prev/next click
    //  on the mini calendar, which actually triggers the data fetch call
    Vue.nextTick(() => {
      const prevButtonFull = document.getElementsByClassName('fc-prev-button fc-button fc-button-primary')[0]
      const prevButtonMini = document.getElementsByClassName('p-datepicker-prev p-link')[0]
      if ((document as any).getElementById('transaction_calendar_container').classList.contains('disable-transaction-calendar-back')) {
        if (prevButtonFull) prevButtonFull.classList.add('no-pointer-events')
      } else {
        if (prevButtonFull) {
          prevButtonFull.classList.remove('no-pointer-events')
          prevButtonFull.addEventListener('click', () => {
            (prevButtonMini as any).click()
          })
        }
      }

      const nextButtonFull = document.getElementsByClassName('fc-next-button fc-button fc-button-primary')[0]
      const nextButtonMini = document.getElementsByClassName('p-datepicker-next p-link')[0]
      if ((document as any).getElementById('transaction_calendar_container').classList.contains('disable-transaction-calendar-forward')) {
        if (nextButtonFull) nextButtonFull.classList.add('no-pointer-events')
      } else {
        if (nextButtonFull) {
          nextButtonFull.classList.remove('no-pointer-events')
          nextButtonFull.addEventListener('click', () => {
            (nextButtonMini as any).click()
          })
        }
      }
    })
  }
}
