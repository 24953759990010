






























































import { Component, Prop, Vue } from 'vue-property-decorator'

import Version from '@/models/version'

@Component
export default class MemberAccessibleVersionCardContent extends Vue {
  @Prop() version!: Version
  @Prop() accountsHash!: { [key: string]: string }
  @Prop() investmentsHash!: { [key: string]: string }
  @Prop() companiesHash!: { [key: string]: string }
  @Prop() membershipsHash!: { [key: string]: string }
  @Prop() fieldValueMethod!: Function
  @Prop() fieldValueAfterMethod!: Function
  @Prop() fieldValueChangedMethod!: Function
}
