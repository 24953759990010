import ApplicationRecord from '@/models/application_record'
import { Investment } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
export default class InvestmentTransaction extends ApplicationRecord {
  static jsonapiType = 'investment_transactions'

  @Attr transactionDate!: Date | null
  @Attr contributionAmount!: number | null
  @Attr distributionAmount!: number | null
  @Attr isJournalEntry!: boolean
  @Attr memo!: string
  @Attr runningBalance!: number | null
  @Attr interestRate!: number | null
  @Attr repaymentAmount!: number | null
  @Attr interestAmount!: number | null

  @Attr investmentId!: string
  @Attr accountTransactionId!: string

  @Attr({ persist: false }) created_at!: Date
  @Attr({ persist: false }) updated_at!: Date

  @BelongsTo() investment!: Investment

  get type (): string {
    if (this.id === undefined) {
      return 'new'
    } else if (this.id && this.accountTransactionId) {
      return 'derived'
    } else if (this.id && !this.accountTransactionId) {
      return 'pure'
    }
    return 'unknown'
  }
}
