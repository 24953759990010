import Papa from 'papaparse'

const exportJSONToCSV = (input: any, filename: string): void => {
  const csv = Papa.unparse(input)
  const link = document.createElement('a')
  const blob = new Blob([csv], { type: 'text/csv' })
  link.href = window.URL.createObjectURL(blob)

  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default exportJSONToCSV
