import ApplicationRecord from '@/models/application_record'

import {
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class Version extends ApplicationRecord {
  static jsonapiType = 'versions'

  @Attr itemType!: string
  @Attr itemId!: string
  @Attr event!: string
  @Attr whodunnit!: string
  @Attr object!: string
  @Attr objectIdentifier!: string
  @Attr objectChanges!: string
  @Attr friendlyObjectChanges!: { [key: string]: string[] }
  @Attr createdAt!: Date
}
