import ApplicationRecord from '@/models/application_record'
import { Model, Attr } from 'spraypaint'

export type Operator = '+' | '-' | '*' | '/'
export type OperationalType = 'Valuation' | 'MethodTemplate'
export type OperationalId = string

@Model()
export class Operation extends ApplicationRecord {
  static jsonapiType = 'operations'

  @Attr operationalType!: OperationalType
  @Attr operationalId!: string
  @Attr sequenceIndex!: number
  @Attr amount!: number | null
  @Attr operator!: Operator
  @Attr label!: string

  static makeDefaultRecord (options: { [key: string]: any }) {
    return new Operation({
      operationalType: options.operationalType,
      operationalId: options.operationalId || '',
      sequenceIndex: options.sequenceIndex || 0,
      operator: options.operator || '+',
      amount: options.amount || null,
      label: options.label || '',
      id: ''
    })
  }

  static async saveRecordsWithAmounts (operationsArray: Operation[]) {
    const saveableOperations = operationsArray.filter(operation => operation.amount !== null)
    for await (const operation of saveableOperations) {
      operation.save()
    }
    return saveableOperations
  }

  static updateSequenceIndices (operations: Operation[]) {
    operations.forEach((operation, index) => {
      if (operation.sequenceIndex !== index) {
        operation.sequenceIndex = index
      }
    })
  }

  static insertRecordAtIndex (operations: Operation[], insertionIndex: number, options: { [key: string]: any }) {
    const newOperations = [...operations]
    const newOperation = Operation.makeDefaultRecord(options)
    newOperations.splice(insertionIndex, 0, newOperation)
    Operation.updateSequenceIndices(newOperations)
    return newOperations
  }

  static async destroyOperationAtIndex (operations: Operation[], index: number) {
    const newOperations = await Operation.destroyRecordAtIndex(operations, index)
    Operation.updateSequenceIndices(newOperations)
    return newOperations
  }
}

export default Operation
