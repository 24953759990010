import ApplicationRecord from '@/models/application_record'
import { Membership, Account, Investment } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

@Model()
export default class MemberAccessible extends ApplicationRecord {
  static jsonapiType = 'member_accessibles'

  @Attr memberId!: string
  @Attr accessibleId!: string
  @Attr accessibleType!: string

  @BelongsTo() member!: Membership
  @BelongsTo() accessible!: Account | Investment
}
