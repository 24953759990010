





























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import Version from '@/models/version'
import Investment from '@/models/investment'

@Component
export default class InvestmentTransactionVersionCardContent extends Vue {
  @Prop() version!: Version
  @Prop() investmentsHash!: { [key: string]: string }
  @Prop() investmentsLookup!: { [key: string]: Investment }
  @Prop() fieldValueMethod!: Function
  @Prop() fieldValueAfterMethod!: Function
  @Prop() fieldValueChangedMethod!: Function

  isLendingInvestmentType (investmentId: string) {
    return this.investmentsLookup[investmentId].investmentType === 'lending'
  }
}
