




















import { Component, Prop, Vue } from 'vue-property-decorator'

import Version from '@/models/version'

@Component
export default class CompanyVersionCardContent extends Vue {
  @Prop() version!: Version
  @Prop() fieldValueMethod!: Function
  @Prop() fieldValueAfterMethod!: Function
  @Prop() fieldValueChangedMethod!: Function
}
