import ApplicationRecord from '@/models/application_record'

import {
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class MemberPreference extends ApplicationRecord {
  static jsonapiType = 'member_preferences'

  @Attr defaultCompanyId!: string
  @Attr defaultProjectDateFilter!: string
  @Attr defaultProjectAssigneeFilter!: string
  @Attr accountTransactionsTableColumns!: string[]
}
