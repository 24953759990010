import ApplicationRecord from '@/models/application_record'
import { MethodTemplate, Operation, Attachment } from '@/models'
import dayjs from 'dayjs'

import {
  Model,
  Attr,
  HasMany,
  BelongsTo
} from 'spraypaint'
import roundToCents from '@/utils/roundToCents'

export interface ValuationAttrs {
  methodTemplateId?: string | null | undefined;
  investmentId?: string;

  investorEquity?: number | null;
  equityPercent?: number | null;
  value?: number | null;
  date?: string | Date;
  notes?: string;
}

export type ValuationAttrMap = {
  [p in keyof ValuationAttrs]: Valuation[p]
}

@Model()
export class Valuation extends ApplicationRecord {
  static jsonapiType = 'valuations'

  @Attr methodTemplateId?: string | null | undefined
  @Attr investmentId!: string

  @Attr investorEquity!: number | null
  @Attr equityPercent!: number | null
  @Attr value!: number | null
  @Attr date!: string | Date
  @Attr notes!: string
  @Attr attachmentCount!: number

  @HasMany() operations!: Operation[]
  @BelongsTo() methodTemplate?: MethodTemplate
  @HasMany() documents!: Attachment[]

  methodDisplayName () {
    if (this.operations.length && this.methodTemplateId) return this.methodTemplate!.name
    if (this.operations.length && !this.methodTemplateId) return 'Custom'
    return ''
  }

  mapAttrsToInstance (attributes: ValuationAttrMap) {
    const instance: any = (() => this)()
    for (const [attr, value] of Object.entries(attributes)) {
      instance[attr] = value
    }
  }

  removeTemplateReferences () {
    if (this?.methodTemplateId || this?.methodTemplate) {
      this.methodTemplateId = null
      delete this.methodTemplate
    }
  }

  static async getByMostRecentDateAttr (investmentId: string) {
    const valuation = (await Valuation
      .where({ investmentId })
      .order({ date: 'desc' })
      .order({ id: 'desc' })
      .first()).data
    return valuation
  }

  static makeDefaultRecord (investmentId: string) {
    const date = new Date()
    const formattedDate = dayjs(date).format('MM/DD/YYYY')
    return new Valuation({
      investmentId,
      methodTemplateId: null,
      equityPercent: null,
      investorEquity: null,
      date: formattedDate,
      value: null,
      notes: ''
    })
  }

  recalculateInvestorEquity () {
    const { value: v, equityPercent: ep, investorEquity: ie } = this
    const investorEquityIsOnlyMissingInput = v && !ie && ep
    const allNumberInputsValid = v && ie && ep
    if (investorEquityIsOnlyMissingInput || allNumberInputsValid) {
      this.investorEquity = roundToCents(this.value! * (this.equityPercent! / 100))
    }
  }
}

export default Valuation
