

























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import Version from '@/models/version'
import Investment from '@/models/investment'

import Card from 'primevue/card'
import AccountTransactionVersionCardContent from './version_card_contents/AccountTransactionVersionCardContent.vue'
import AccountVersionCardContent from './version_card_contents/AccountVersionCardContent.vue'
import InvestmentTransactionVersionCardContent from './version_card_contents/InvestmentTransactionVersionCardContent.vue'
import InvestmentVersionCardContent from './version_card_contents/InvestmentVersionCardContent.vue'
import CompanyVersionCardContent from './version_card_contents/CompanyVersionCardContent.vue'
import ProjectVersionCardContent from './version_card_contents/ProjectVersionCardContent.vue'
import TaskVersionCardContent from './version_card_contents/TaskVersionCardContent.vue'
import TaskAssignmentVersionCardContent from './version_card_contents/TaskAssignmentVersionCardContent.vue'
import MemberAccessibleVersionCardContent from './version_card_contents/MemberAccessibleVersionCardContent.vue'
import MembershipVersionCardContent from './version_card_contents/MembershipVersionCardContent.vue'
import NoteVersionCardContent from './version_card_contents/NoteVersionCardContent.vue'
import PayeeVersionCardContent from './version_card_contents/PayeeVersionCardContent.vue'
import UserVersionCardContent from './version_card_contents/UserVersionCardContent.vue'
import ValuationVersionCardContent from './version_card_contents/ValuationVersionCardContent.vue'

Vue.component('Card', Card)
Vue.component('AccountTransactionVersionCardContent', AccountTransactionVersionCardContent)
Vue.component('AccountVersionCardContent', AccountVersionCardContent)
Vue.component('InvestmentTransactionVersionCardContent', InvestmentTransactionVersionCardContent)
Vue.component('InvestmentVersionCardContent', InvestmentVersionCardContent)
Vue.component('CompanyVersionCardContent', CompanyVersionCardContent)
Vue.component('ProjectVersionCardContent', ProjectVersionCardContent)
Vue.component('TaskVersionCardContent', TaskVersionCardContent)
Vue.component('TaskAssignmentVersionCardContent', TaskAssignmentVersionCardContent)
Vue.component('MemberAccessibleVersionCardContent', MemberAccessibleVersionCardContent)
Vue.component('MembershipVersionCardContent', MembershipVersionCardContent)
Vue.component('NoteVersionCardContent', NoteVersionCardContent)
Vue.component('PayeeVersionCardContent', PayeeVersionCardContent)
Vue.component('UserVersionCardContent', UserVersionCardContent)
Vue.component('ValuationVersionCardContent', ValuationVersionCardContent)

@Component
export default class VersionCard extends Vue {
  @Prop() version!: Version
  @Prop() usersHash!: { [key: string]: string }
  @Prop() membershipsHash!: { [key: string]: string }
  @Prop() accountsHash!: { [key: string]: string }
  @Prop() payeesHash!: { [key: string]: string }
  @Prop() investmentsHash!: { [key: string]: string }
  @Prop() companiesHash!: { [key: string]: string }
  @Prop() projectsHash!: { [key: string]: string }
  @Prop() taskStatusesHash!: { [key: string]: string }
  @Prop() transactionStatusesHash!: { [key: string]: string }
  @Prop() transactionCategoriesHash!: { [key: string]: string }
  @Prop() recurrenceRulesHash!: { [key: string]: string }
  @Prop() tenantsHash!: { [key: string]: string }
  @Prop() investmentsLookup!: { [key: string]: Investment }

  nullString = '<empty>'

  fieldValue (version: Version, fieldName: string) {
    if (version.event === 'create') {
      if (version.objectChanges && version.objectChanges[fieldName as any]) {
        if (version.objectChanges[fieldName as any][1] === this.nullString) return null
        return version.objectChanges[fieldName as any][1] || ''
      }
    } else {
      if (version.object && version.object[fieldName as any]) {
        if (version.object[fieldName as any] === this.nullString) return null
        return version.object[fieldName as any] || ''
      }
    }
    return ''
  }

  fieldValueAfter (version: Version, fieldName: string) {
    if (version.objectChanges && version.objectChanges[fieldName as any]) {
      if (version.objectChanges[fieldName as any][1] === this.nullString) return null
      return version.objectChanges[fieldName as any][1] || ''
    }
    return ''
  }

  fieldValueChanged (version: Version, fieldName: string) {
    if (version.event === 'destroy') return false
    return version.objectChanges &&
      version.objectChanges[fieldName as any] &&
      version.objectChanges[fieldName as any].length > 0
  }
}
