























































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

import Version from '@/models/version'

@Component
export default class VersionActionContent extends Vue {
  @Prop() version!: Version
  @Prop({ default: () => { return '' } }) providedItemType!: string
  @Prop({ default: () => { return '' } }) providedItemId!: string
  @Prop({ default: () => { return false } }) isSignInEvent!: boolean
  @Prop() itemTypesLookup!: Function

  get providedItemFilterPresent () {
    return this.providedItemType !== '' && this.providedItemId !== ''
  }

  get isDestroyEvent () {
    return this.version.event === 'destroy'
  }

  @Emit()
  filterByObject (id: string, type: string) {
    return { id: id, type: type }
  }
}
