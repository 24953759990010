




































import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { Membership, MemberPreference } from '@/models'

import Button from 'primevue/button'
import OverlayPanel from 'primevue/overlaypanel'
import Checkbox from 'primevue/checkbox'

Vue.component('Button', Button)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('Checkbox', Checkbox)

const Auth = namespace('auth')

@Component
export default class ColumnDisplayEditor extends Vue {
  @Auth.Getter currentMemberId!: string

  columns = [
    { name: 'Recurring', value: 'recurring', disabled: true },
    { name: 'Date', value: 'transactionDate', disabled: true },
    { name: 'Account', value: 'accountName', disabled: true },
    { name: 'Payee', value: 'payeeName', disabled: true },
    { name: 'Line Item', value: 'transactionCategory', disabled: false },
    { name: 'Inflow', value: 'inflow', disabled: true },
    { name: 'Outflow', value: 'outflow', disabled: true },
    { name: 'Estimate', value: 'isEstimate', disabled: false },
    { name: 'Equity', value: 'equity', disabled: false },
    { name: 'Status', value: 'transactionStatusName', disabled: true },
    { name: 'Type', value: 'draftType', disabled: false },
    { name: 'Notes', value: 'notes', disabled: false },
    { name: 'Action Buttons', value: 'actionButtons', disabled: true }
  ]

  selectedColumns: string[] = []
  currentMemberPreference = new MemberPreference()

  async mounted () {
    await this.getMemberPreferences()
    this.initSelectedColumns()
  }

  async getMemberPreferences () {
    const currentMember = (await Membership
      .includes('memberPreference')
      .find(this.currentMemberId)
    ).data
    this.currentMemberPreference = currentMember.memberPreference
  }

  async saveMemberPreference () {
    if (!this.currentMemberPreference.isPersisted) return

    if (this.selectedColumns.length === this.columns.length) {
      this.currentMemberPreference.accountTransactionsTableColumns = []
    } else {
      this.currentMemberPreference.accountTransactionsTableColumns = this.selectedColumns
    }
    await this.currentMemberPreference.save()
    this.$emit('after-save-member-preference')
  }

  initSelectedColumns () {
    if (this.currentMemberPreference.accountTransactionsTableColumns.length === 0) {
      this.selectedColumns = this.columns.map(item => item.value)
    } else {
      this.selectedColumns = this.currentMemberPreference.accountTransactionsTableColumns
    }
  }

  openOverlay (event: any) {
    if (this.$refs && this.$refs.display_column_overlay) {
      (this.$refs.display_column_overlay as any).show(event)
    }
  }
}
