















import { Component, Prop, Vue } from 'vue-property-decorator'

import Version from '@/models/version'

@Component
export default class VersionDetailContent extends Vue {
  @Prop() version!: Version

  get changes () {
    if (!this.version.friendlyObjectChanges) return []
    return this.version.friendlyObjectChanges.changes || []
  }

  isSignInEvent (version: Version) {
    const objectChanges: any = version.objectChanges
    return version.event === 'update' &&
      version.itemType === 'User' &&
      objectChanges &&
      objectChanges.sign_in_count &&
      objectChanges.sign_in_count[0] + 1 === objectChanges.sign_in_count[1]
  }
}
