























































































































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'
import dayjs from 'dayjs'

import Account from '@/models/account'
import AccountTransaction from '@/models/account_transaction'
import TransactionStatus from '@/models/transaction_status'

import AppInputCurrency from '@/components/AppInputCurrency'
import Button from 'primevue/button'
import Toolbar from 'primevue/toolbar'
import OverlayPanel from 'primevue/overlaypanel'
import InputNumber from 'primevue/inputnumber'
import InlineMessage from 'primevue/inlinemessage'

Vue.component('AppInputCurrency', AppInputCurrency)
Vue.component('Button', Button)
Vue.component('Toolbar', Toolbar)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('InputNumber', InputNumber)
Vue.component('InlineMessage', InlineMessage)

const Auth = namespace('auth')

@Component
export default class AccountReconcileToolbar extends Vue {
  @Prop() accountId!: string
  @Auth.Getter currentPermissions!: string

  adjustment: AccountTransaction | null = null
  account: Account | null = null
  currentStep = 1
  enteredBalance: number | null = null
  showAdjustmentMessage = false

  enableCreate = false

  get adjustmentAmount () {
    if (this.enteredBalance && this.account && this.account.clearedBalance) {
      return this.enteredBalance - this.account.clearedBalance
    }
  }

  get workingBalance () {
    return this.account ? (this.account.clearedBalance * 1.0 + this.account.pendingBalance * 1.0) : 0.0
  }

  @Watch('accountId')
  accountChanged () {
    this.refreshAccountBalances()
    this.resetReconcileFlow()
  }

  async mounted () {
    this.getCurrentPermissions()
    this.refreshAccountBalances()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableCreate = permissions.includes('create_account_transaction')
  }

  async refreshAccountBalances () {
    this.account = (await Account.find(this.accountId)).data
  }

  prepareAdjustment () {
    if (!this.enteredBalance) this.enteredBalance = 0.0
    if (this.$refs && this.$refs.reconcile_overlay_panel) {
      (this.$refs.reconcile_overlay_panel as any).hide()
    }
    this.showAdjustmentMessage = true
  }

  @Emit()
  async createAdjustment () {
    const clearedStatus = (await TransactionStatus.where({
      name: 'Cleared'
    }).all()).data[0]

    const accountId = this.account ? this.account.id : null
    const inflow = (this.adjustmentAmount && this.adjustmentAmount >= 0) ? this.adjustmentAmount : null
    const outflow = (this.adjustmentAmount && this.adjustmentAmount < 0) ? -1 * this.adjustmentAmount : null
    const investmentId = this.account ? this.account.defaultInvestmentId : null

    this.adjustment = new AccountTransaction({
      transactionDate: new Date(dayjs().format('YYYY-MM-DD')),
      transactionStatusId: clearedStatus.id,
      accountId: accountId,
      draftType: 'adjustment',
      payeeId: accountId,
      payeeType: 'Account',
      inflow: inflow,
      outflow: outflow,
      investmentId: investmentId
    })

    await this.adjustment.save()

    const summaryMessage = this.account ? this.account.name : 'Unknown Account'
    const detailMessage = !this.adjustmentAmount ? 'No adjustments needed' : 'An adjustment has been created'
    this.$toast.add({ severity: 'success', summary: summaryMessage, detail: detailMessage, group: 'cashflow', life: 3000 })

    this.cancelReconcile()
    this.refreshAccountBalances()
    this.goToStep(3)

    const refs = this.$refs
    Vue.nextTick(() => {
      if (refs && refs.reconcile_overlay_panel) {
        (refs.reconcile_overlay_panel as any).show()
      }
    })
  }

  @Emit()
  async updateBooksReconcileDate () {
    this.adjustment!.isBooksVerification = true
    await this.adjustment!.save()

    const summaryMessage = this.account ? this.account.name : 'Unknown Account'
    const detailMessage = 'Verified books balance'
    this.$toast.add({ severity: 'success', summary: summaryMessage, detail: detailMessage, group: 'cashflow', life: 3000 })

    this.cancelReconcile()
    this.refreshAccountBalances()
  }

  cancelReconcile () {
    this.resetReconcileFlow()
    if (this.$refs && this.$refs.reconcile_overlay_panel) {
      (this.$refs.reconcile_overlay_panel as any).hide()
    }
  }

  goToStep (step: number) {
    this.enteredBalance = null
    if (step !== undefined) {
      this.currentStep = step
    } else {
      this.currentStep += 1
    }
  }

  resetReconcileFlow () {
    this.enteredBalance = null
    this.currentStep = 1
    this.showAdjustmentMessage = false
  }

  toggleReconcileOverlay (event: any) {
    this.resetReconcileFlow()
    if (this.$refs && this.$refs.reconcile_overlay_panel) {
      (this.$refs.reconcile_overlay_panel as any).toggle(event)
    }
  }
}
