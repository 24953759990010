import {
  AccountTransaction,
  Payee,
  TransactionCategory,
  TransactionStatus
} from '@/models'
import dayjs from 'dayjs'

export class AccountTransactionSaver {
  transaction!: AccountTransaction
  payees!: Payee[]
  transactionCategories!: TransactionCategory[]
  transactionStatuses!: TransactionStatus[]
  refs!: any

  constructor (
    transaction: AccountTransaction,
    payees: Payee[],
    transactionCategories: TransactionCategory[],
    transactionStatuses: TransactionStatus[],
    refs: any
  ) {
    this.transaction = transaction
    this.payees = payees
    this.transactionCategories = transactionCategories
    this.transactionStatuses = transactionStatuses
    this.refs = refs
  }

  determineSaveType () {
    let forceSingle = false
    let forceRecurring = false

    const forecastId = this.transactionStatuses.filter(item => { return item.name === 'Forecast' })
    const isTransactionStatusChange = String(this.transaction.transactionStatusId) !== String(this.transaction.transactionStatus?.id)
    const isForecastStatus = this.transaction.transactionStatus?.name === 'Forecast' || String(this.transaction.transactionStatusId) === String(forecastId)
    if (isTransactionStatusChange || !isForecastStatus) {
      forceSingle = true
    }

    let recurrenceRuleWasChanged = false
    const recurrenceRuleWasTouched = this.transaction.isDirty() && Object.keys(this.transaction.changes()).includes('recurrenceRuleId')
    if (recurrenceRuleWasTouched) {
      const changes = this.transaction.changes().recurrenceRuleId
      const oldValue = changes![0]
      const newValue = changes![1]
      if (oldValue !== newValue) recurrenceRuleWasChanged = true

      if (oldValue && recurrenceRuleWasChanged) forceRecurring = true
      if (!oldValue && recurrenceRuleWasChanged) forceSingle = true
    }

    if (forceRecurring) return 'recurring'
    if (forceSingle) return 'single'
    if (this.transaction.isRecurring) return 'recurringWithPrompt'
    return 'single'
  }

  async saveSingle () {
    const wasNew = this.transaction.type === 'new'
    if (!wasNew && !(this.transaction.type === 'single' && String(this.transaction.transactionStatusId) === '1')) {
      this.transaction.recurrenceRuleId = null
      this.transaction.recurrenceRule = null
    }

    // create new payee/category if necessary
    const requiredNewPayee = await this.translatePayeeField()
    const requiredNewCategory = await this.translateCategoryField()

    if (this.transaction.type === 'generated') {
      this.transaction.isPersisted = false
    }

    const associations = this.getAssociationsForTransaction()
    try {
      await this.transaction.save({
        with: associations
      })
    } catch (e) {
      if (this.transaction.reverseTransactionId && this.transaction.payeeType === 'Payee') {
        return {
          errorMessage: 'Transactions may not be changed from transfers to non-transfers'
        }
      }
    }

    const result: any = {}
    result.transaction = this.transaction
    result.requiredNewPayee = this.transaction.payee && requiredNewPayee
    result.requiredNewCategory = this.transaction.transactionCategory && requiredNewCategory
    result.wasNew = wasNew
    return result
  }

  async saveAllFuture () {
    // get recurrence parent
    if (!this.transaction.parentTransactionId) { return }
    const parentTransaction = (await AccountTransaction
      .find(this.transaction.parentTransactionId)
    ).data
    const rootId = parentTransaction.rootTransactionId ? parentTransaction.rootTransactionId : parentTransaction.id

    // create new recurrence starting at transactionDate
    const newParentTransaction = new AccountTransaction(this.transaction.attributes)
    newParentTransaction.parentTransactionId = null
    newParentTransaction.originalOccurrenceDate = null
    newParentTransaction.rootTransactionId = rootId || null
    newParentTransaction.recurrenceEndDate = this.transaction.seriesEndDate
    if (this.transaction.recurrenceRule && this.transaction.recurrenceRule.id) {
      newParentTransaction.recurrenceRuleId = this.transaction.recurrenceRule.id
    } else {
      newParentTransaction.recurrenceRuleId = parentTransaction.recurrenceRuleId
    }
    newParentTransaction.isPersisted = false

    // send flag signifying desire to persist for all future transactions
    newParentTransaction.persistForFutureFrom = parentTransaction.id

    // send the original transaction date
    let originalTransactionDate = this.transaction.changes().transactionDate![0]
    if (this.transaction.type === 'exception') {
      originalTransactionDate = this.transaction.originalOccurrenceDate
    }
    newParentTransaction.originalTransactionDate = originalTransactionDate!

    // translate payee field
    const requiredNewPayee = await this.translatePayeeField()
    newParentTransaction.payee = this.transaction.payee
    newParentTransaction.payeeId = this.transaction.payeeId
    newParentTransaction.payeeType = this.transaction.payeeType

    // translate category field
    const requiredNewCategory = await this.translateCategoryField()
    newParentTransaction.transactionCategory = this.transaction.transactionCategory
    newParentTransaction.transactionCategoryId = this.transaction.transactionCategoryId

    const associations = this.getAssociationsForTransaction()
    try {
      await newParentTransaction.save({
        with: associations
      })
    } catch (e) {
      if (newParentTransaction.reverseTransactionId && newParentTransaction.payeeType === 'Payee') {
        return {
          errorMessage: 'Transactions may not be changed from transfers to non-transfers'
        }
      }
    }

    const result: any = {}
    result.transaction = newParentTransaction
    result.requiredNewPayee = newParentTransaction.payee && requiredNewPayee
    result.requiredNewCategory = newParentTransaction.transactionCategory && requiredNewCategory
    return result
  }

  getAssociationsForTransaction () {
    const associations: string[] = []
    if (this.transaction.account !== undefined) associations.push('account.id')
    if (this.transaction.transactionStatus !== undefined) associations.push('transactionStatus.id')
    if (this.transaction.transactionCategory !== undefined) associations.push('transactionCategory.id')
    if (this.transaction.investment !== undefined) associations.push('investment.id')
    if (this.transaction.payee !== undefined) associations.push('payee')
    if (this.transaction.recurrenceRule !== undefined) associations.push('recurrenceRule.id')
    return associations
  }

  private async translatePayeeField () {
    let requiredNewPayee = false

    let autocompleteInputValue: string | undefined
    if (this.refs && this.refs['autocompletePayee-' + this.transaction.uid]) {
      autocompleteInputValue = (this.refs['autocompletePayee-' + this.transaction.uid] as any).inputTextValue
    }
    if (this.transaction.payee && this.transaction.payee.id) {
      // user selected an existing payee without typing
      this.transaction.payeeId = this.transaction.payee.id
      this.transaction.payeeType = this.transaction.payee.type
    } else if (autocompleteInputValue && autocompleteInputValue.length > 0) {
      // user typed in payee
      const matchingExistingPayees = this.payees.filter(item => {
        return item.name === autocompleteInputValue
      })
      if (matchingExistingPayees.length > 0) {
        // user typed in payee, but system found a match already existing
        this.transaction.payee = matchingExistingPayees[0]
        this.transaction.payeeId = this.transaction.payee!.id || null
        this.transaction.payee!.type = 'Payee'
      } else {
        // user typed in payee, system could not find a match– create new payee
        requiredNewPayee = true
        const newPayee = new Payee({
          name: autocompleteInputValue
        })
        await newPayee.save()
        this.transaction.payee = newPayee
        this.transaction.payeeId = newPayee.id || null
        this.transaction.payeeType = 'Payee'
      }
    } else {
      // user left payee blank
      this.transaction.payee = null
      this.transaction.payeeType = null
      this.transaction.payeeId = null
    }
    return requiredNewPayee
  }

  private async translateCategoryField () {
    let requiredNewCategory = false

    let autocompleteInputValue: string | undefined
    if (this.refs && this.refs['autocompleteCategory-' + this.transaction.uid]) {
      autocompleteInputValue = (this.refs['autocompleteCategory-' + this.transaction.uid] as any).inputTextValue
    }
    if (this.transaction.transactionCategory && this.transaction.transactionCategory.id) {
      // user selected an existing category without typing
      this.transaction.transactionCategoryId = this.transaction.transactionCategory.id
    } else if (autocompleteInputValue && autocompleteInputValue.length > 0) {
      // user typed in category
      const matchingExistingCategories = this.transactionCategories.filter(item => {
        return item.name === autocompleteInputValue
      })
      if (matchingExistingCategories.length > 0) {
        // user typed in category, but system found a match already existing
        this.transaction.transactionCategory = matchingExistingCategories[0]
        this.transaction.transactionCategoryId = this.transaction.transactionCategory.id || null
      } else {
        // user typed in category, system could not find a match– create new category
        requiredNewCategory = true
        const newCategory = new TransactionCategory({
          name: autocompleteInputValue
        })
        await newCategory.save()
        this.transaction.transactionCategory = newCategory
        this.transaction.transactionCategoryId = newCategory.id || null
      }
    } else {
      // user left category blank
      this.transaction.transactionCategory = null
      this.transaction.transactionCategoryId = null
    }
    return requiredNewCategory
  }
}
