import ApplicationRecord from '@/models/application_record'
import { Account } from '@/models'

import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

import abbreviateYear from '@/utils/abbreviateYear'

type Filters = { [key: string]: any }

@Model()
export default class AccountTransactionSummary extends ApplicationRecord {
  static jsonapiType = 'account_transaction_summaries'

  @Attr year!: number
  @Attr month!: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  @Attr transactionTotalExcludingAdjustments!: number
  @Attr clearedTotalExcludingAdjustments!: number
  @Attr forecastTotalExcludingEstimates!: number
  @Attr transactionType!: 'inflow' | 'outflow'
  @Attr transactionTotal!: number
  @Attr accountId!: string | null
  @Attr includesEquity!: boolean
  @Attr forecastTotal!: number
  @Attr clearedTotal!: number
  @Attr pendingTotal!: number
  @Attr updatedAt!: Date

  @BelongsTo() account!: Account

  static async fetchSummariesByYear (year: number, queryFilter: Filters) {
    const summaries = (await AccountTransactionSummary
      .page(1)
      .per(12)
      .where({ year, ...queryFilter })
      .order('month')
      .all()
    ).data
    return summaries
  }

  static async fetchCurrentYearSummaries (queryFilter: Filters) {
    const date = new Date()
    const summaries = await this.fetchSummariesByYear(date.getFullYear(), queryFilter)
    return summaries
  }

  static async fetchLastYearSummaries (queryFilter: Filters) {
    const date = new Date()
    const summaries = await this.fetchSummariesByYear(date.getFullYear() - 1, queryFilter)
    return summaries
  }

  static async fetchLastTwelveMonths (queryFilter: Filters = {}) {
    const currentYearSummaries = await AccountTransactionSummary.fetchCurrentYearSummaries(queryFilter)
    if (currentYearSummaries.length === 12) return currentYearSummaries

    const lastYearSummaries = await AccountTransactionSummary.fetchLastYearSummaries(queryFilter)
    const lastYearUsedMonths = lastYearSummaries.slice(-(12 - currentYearSummaries.length))
    const twelveMonthsSummaries = [...lastYearUsedMonths, ...currentYearSummaries]

    return twelveMonthsSummaries
  }

  get monthName () {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ]
    return monthNames[this.month - 1]
  }

  get abbreviatedYear () {
    return abbreviateYear(this.year)
  }

  get displayMonth () {
    const abbreviatedMonthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
    const abbreviatedMonthName = abbreviatedMonthNames[this.month - 1]
    return `${abbreviatedMonthName}, ${this.abbreviatedYear}`
  }
}
