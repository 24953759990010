




























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import Version from '@/models/version'

@Component
export default class AccountTransactionVersionCardContent extends Vue {
  @Prop() version!: Version
  @Prop() accountsHash!: { [key: string]: string }
  @Prop() payeesHash!: { [key: string]: string }
  @Prop() investmentsHash!: { [key: string]: string }
  @Prop() transactionStatusesHash!: { [key: string]: string }
  @Prop() transactionCategoriesHash!: { [key: string]: string }
  @Prop() recurrenceRulesHash!: { [key: string]: string }
  @Prop() fieldValueMethod!: Function
  @Prop() fieldValueAfterMethod!: Function
  @Prop() fieldValueChangedMethod!: Function
}
