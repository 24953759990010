




















































import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator'

import { AccountTransaction, Investment } from '@/models'

import OverlayPanel from 'primevue/overlaypanel'
import Button from 'primevue/button'
import ToggleButton from 'primevue/togglebutton'
import Dropdown from 'primevue/dropdown'

Vue.component('OverlayPanel', OverlayPanel)
Vue.component('Button', Button)
Vue.component('ToggleButton', ToggleButton)
Vue.component('Dropdown', Dropdown)

@Component
export default class EquityEditor extends Vue {
  @Prop() transaction!: AccountTransaction
  @Prop() investments!: Investment[]

  selectedInvestmentId: string | null = null
  showingOverlay = false

  get changesExist () {
    if (!this.transaction.investmentId && !this.selectedInvestmentId) return false
    return String(this.transaction.investmentId) !== String(this.selectedInvestmentId)
  }

  get investmentName () {
    const id = this.transaction.investmentId
    const foundInvestments = this.investments.filter(item => {
      return String(item.id) === String(id)
    })
    if (!foundInvestments || foundInvestments.length === 0) return
    return foundInvestments[0].name
  }

  get tooltipContent () {
    if (this.disableEquityButton) {
      const disabledExplanation = 'Select an investment account as Account or Payee to enable this field'
      return disabledExplanation
    }
    return this.investmentName || 'Equity'
  }

  get disableEquityButton () {
    const accountIsInvestmentAccount = this.transaction.account &&
      this.transaction.account.isInvestmentAccount
    const payeeIsInvestmentAccount = this.transaction.payee &&
      this.transaction.payee.type === 'Account' &&
      (this.transaction.payee as any).isInvestmentAccount
    return this.isAdjustment() || !(accountIsInvestmentAccount || payeeIsInvestmentAccount)
  }

  @Watch('transaction.account')
  onTransactionAccountChanged () {
    this.hideEquityOverlay()
    this.transaction.investmentId = null
  }

  mounted () {
    this.initSelectedInvestmentId()
  }

  initSelectedInvestmentId () {
    if (this.transaction.investmentId) {
      this.selectedInvestmentId = String(this.transaction.investmentId)
    } else {
      this.selectedInvestmentId = null
    }
  }

  showEquityOverlay (event: any = {}) {
    (this.$refs.equity_overlay as any).show(event)
    this.showingOverlay = true
    if (!this.transaction.investmentId) {
      this.setDefaultInvestment()
    }
  }

  hideEquityOverlay (event: any = {}) {
    (this.$refs.equity_overlay as any).hide(event)
    this.showingOverlay = false
  }

  toggleEquityOverlay (event: any = {}) {
    if (this.showingOverlay) {
      this.hideEquityOverlay(event)
    } else {
      this.showEquityOverlay(event)
    }
  }

  setDefaultInvestment () {
    if (this.transaction &&
      this.transaction.account &&
      this.transaction.account.defaultInvestmentId
    ) {
      this.selectedInvestmentId = String(this.transaction.account.defaultInvestmentId)
    }
  }

  isAdjustment () {
    return this.transaction.draftType === 'adjustment' ||
      this.transaction.draftType === 'initial_adjustment'
  }

  cancel () {
    if (this.changesExist) this.initSelectedInvestmentId()
    this.hideEquityOverlay()
  }

  confirm () {
    this.transaction.investmentId = this.selectedInvestmentId
    this.transaction.investment = this.investments.filter(item => {
      return String(item.id) === String(this.selectedInvestmentId)
    })[0]
    this.hideEquityOverlay()
  }
}
