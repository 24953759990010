



























































































































































































































































import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import dayjs from 'dayjs'

import AccountTransaction from '@/models/account_transaction'
import TransactionStatus from '@/models/transaction_status'
import Note from '@/models/note'
import User from '@/models/user'

import AppDialog from '@/components/AppDialog'
import DataTable from 'primevue/datatable'
import Card from 'primevue/card'
import SelectButton from 'primevue/selectbutton'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import AppVersionsTable from '@/components/AppVersionsTable'
import AppColorTag from '@/components/AppColorTag'
import TransactionDetailSection from './TransactionDetailSection.vue'

Vue.component('AppDialog', AppDialog)
Vue.component('DataTable', DataTable)
Vue.component('Card', Card)
Vue.component('SelectButton', SelectButton)
Vue.component('TabView', TabView)
Vue.component('TabPanel', TabPanel)
Vue.component('AppVersionsTable', AppVersionsTable)
Vue.component('AppColorTag', AppColorTag)
Vue.component('TransactionDetailSection', TransactionDetailSection)

const Auth = namespace('auth')

@Component
export default class AccountTransactionDetailPanel extends Vue {
  @Prop() providedTransaction!: AccountTransaction
  @Auth.Getter currentPermissions!: string
  @Auth.Getter currentUser!: string
  @Auth.Getter uid!: string

  loading = false
  showNoteDeleteDialog = false
  notePendingDelete: Note | null = null
  transaction = this.providedTransaction
  originalNotes: Note[] = []
  notes: Note[] = []
  selectedNote: Note | null = null
  loggedInUser: User | null = null
  newNote = new Note()
  transactionStatuses: TransactionStatus[] = []
  editingRows: Note[] = []
  selectedTab: string | null = 'Notes'

  showRecurringTransactionDeleteDialog = false
  showSingleTransactionDeleteDialog = false

  editMode = false
  processing = false

  enableUpdate = false
  enableDestroy = false
  enableReadVersion = false

  @Watch('selectedTab')
  onChangeSelectedTab (newValue: string, oldValue: string) {
    if (!newValue) this.selectedTab = oldValue
  }

  mounted () {
    this.getCurrentPermissions()
    this.resetNewNote()
    this.resetEditingRows()
    this.focusNewNote()
    this.getNotes()
    this.getTransactionStatuses()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableUpdate = permissions.includes('update_account_transaction')
    this.enableDestroy = permissions.includes('destroy_account_transaction')
    this.enableReadVersion = permissions.includes('read_version')
  }

  async getTransaction () {
    if (!this.transaction.id) return
    const response = await AccountTransaction
      .includes('account')
      .includes('transactionStatus')
      .includes('transactionCategory')
      .includes('investment')
      .includes('payee')
      .includes('recurrenceRule')
      .includes('parentTransaction')
      .includes('rootTransaction')
      .includes('updatedBy')
      .includes('createdBy')
      .selectExtra(['seriesEndDate'])
      .find(this.transaction.id)
    this.transaction = response.data
  }

  async getNotes () {
    this.loading = true
    const response = await Note
      .where({
        notableType: 'AccountTransaction',
        notableId: this.transaction.id ? this.transaction.id : -1
      })
      .includes('updatedBy')
      .order({ createdAt: 'desc' })
      .order('id')
      .per(99999)
      .all()
    this.$set(this, 'notes', response.data)
    this.loading = false
  }

  async getCurrentUser () {
    const response = await User.where({
      uid: this.uid,
      name: this.currentUser
    }).all()
    this.loggedInUser = (response && response.data && response.data[0]) ? response.data[0] : null
  }

  async getTransactionStatuses () {
    this.transactionStatuses = (await TransactionStatus.per(1000).all()).data
  }

  async saveTransaction () {
    this.processing = true
    if (this.transaction.type === 'generated') {
      await this.createException(this.transaction)
      this.newNote.notableId = this.transaction.id || null
    }
    await this.transaction.save()
    this.transactionChanged(this.transaction)
    this.editMode = false

    // trigger audit refresh
    const currentTab = this.selectedTab
    this.selectedTab = null
    Vue.nextTick(() => {
      this.selectedTab = currentTab
    })

    this.processing = false
  }

  async promptForDeleteTransaction () {
    if (!this.transaction) return
    const transaction = this.transaction

    if (transaction.isRecurring) {
      this.showRecurringTransactionDeleteDialog = true
    } else {
      this.showSingleTransactionDeleteDialog = true
    }
  }

  async deleteSingleTransaction () {
    if (!this.transaction) return
    const transaction = this.transaction

    let success = false
    if (transaction.type === 'single') {
      // delete it normally
      success = await transaction.destroy()
    } else if (transaction.type === 'generated') {
      // create exception on parent with blank transaction date to signify deletion
      transaction.transactionDate = null
      transaction.isPersisted = false

      // nullify recurrence fields (required to represent an exception)
      transaction.recurrenceRuleId = null
      transaction.recurrenceRule = null

      // disregard current status and make it forecast to preserve pre-computations
      //  performed on pending/cleared transactions
      transaction.transactionStatus = this.transactionStatuses.filter((status) => {
        return status.name === 'Forecast'
      })[0]

      success = await transaction.save({ with: ['transactionStatus.id'] })
    } else if (transaction.type === 'exception') {
      // update existing exception with blank transaction date to signify deletion
      transaction.transactionDate = null

      // disregard current status and make it forecast to preserve pre-computations
      //  performed on pending/cleared transactions
      transaction.transactionStatus = this.transactionStatuses.filter((status) => {
        return status.name === 'Forecast'
      })[0]

      success = await transaction.save({ with: ['transactionStatus.id'] })
    }

    if (!success) {
      // TODO: implement error dialog
      return
    }

    this.transactionDeleted()
    this.closeDeleteDialog()
  }

  async deleteAllFutureTransactions () {
    if (!this.transaction) return
    const transaction = this.transaction

    // get recurrence parent
    if (!transaction.parentTransactionId) { return }
    const parentTransaction = (await AccountTransaction
      .find(transaction.parentTransactionId)
    ).data

    // end parent early and delete all future related recurrences/exceptions
    if (transaction.transactionDate) {
      parentTransaction.recurrenceEndDate = new Date(dayjs(transaction.transactionDate).subtract(1, 'day').format('YYYY-MM-DD'))
    }

    // send flag signifying desire to persist for all future transactions
    parentTransaction.persistForFutureFrom = parentTransaction.id

    const success = await parentTransaction.save()

    if (!success) {
      // TODO: implement error dialog
      return
    }

    this.transactionDeleted()
    this.closeDeleteDialog()
  }

  async createNote (note: Note) {
    await this.saveNote(note)
    await this.resetNewNote()
    this.resetEditingRows()
  }

  async updateNote (note: Note) {
    if (this.loggedInUser) {
      note.updatedBy = this.loggedInUser
    }
    await this.saveNote(note)
    this.resetEditingRows()
  }

  async saveNote (note: Note) {
    if (this.transaction.type === 'generated') {
      await this.createException(this.transaction)
      note.notableId = this.transaction.id || null
    }
    await note.save({
      with: [
        'createdBy.id',
        'updatedBy.id'
      ]
    })
    this.getNotes()

    this.transaction.noteCount += 1
    this.transactionChanged()
  }

  promptForDeleteNote (note: Note) {
    this.notePendingDelete = note
    this.resetEditingRows()
    this.showNoteDeleteDialog = true
  }

  async deleteNote (note: Note | null = this.notePendingDelete) {
    if (!note) return
    await note.destroy()
    this.showNoteDeleteDialog = false
    this.getNotes()

    this.transaction.noteCount -= 1
    this.transactionChanged(this.transaction)
  }

  async resetEditableFields () {
    let transactionIdWithContent: string | null | undefined = this.transaction.id
    if (!transactionIdWithContent) transactionIdWithContent = this.transaction.parentTransactionId
    if (!transactionIdWithContent) transactionIdWithContent = this.transaction.rootTransactionId
    if (!transactionIdWithContent) return
    const response = await AccountTransaction
      .find(transactionIdWithContent)

    // reset fields
    this.transaction.transactionCategoryId = response.data.transactionCategoryId
  }

  async resetNewNote () {
    await this.getCurrentUser()
    this.newNote = new Note({
      notableType: 'AccountTransaction',
      notableId: this.transaction.id,
      createdBy: this.loggedInUser,
      updatedBy: this.loggedInUser
    })
    this.focusNewNote()
  }

  resetEditingRows () {
    this.editingRows = [this.newNote]
  }

  onRowSelect () {
    this.resetEditingRows()
  }

  onRowEditCancel () {
    this.editingRows = [this.newNote]
  }

  onRowEditInit (note: Note) {
    this.editingRows = [this.newNote, note]
    this.selectedNote = note
  }

  closeDeleteDialog () {
    this.showSingleTransactionDeleteDialog = false
    this.showRecurringTransactionDeleteDialog = false
  }

  enterEditMode () {
    this.editMode = true
  }

  async exitEditMode () {
    this.processing = true
    await this.resetEditableFields()
    this.transactionChanged(this.transaction)
    this.editMode = false
    this.processing = false
  }

  async createException (transaction: AccountTransaction) {
    // create exception on parent
    transaction.isPersisted = false

    // nullify recurrence fields (required to represent an exception)
    transaction.recurrenceRuleId = null
    transaction.recurrenceRule = null

    // disregard current status and make it forecast to preserve pre-computations
    //  performed on pending/cleared transactions
    transaction.transactionStatus = this.transactionStatuses.filter((status) => {
      return status.name === 'Forecast'
    })[0]

    await transaction.save({ with: ['transactionStatus.id'] })
    this.exceptionCreated()
  }

  focusNewNote () {
    const refs = this.$refs
    setTimeout(() => {
      if (refs && refs.new_note_body && refs.new_note_body) {
        (refs.new_note_body as any).$el.focus()
      }
    }, 200)
  }

  noteIsValid (note: Note) {
    if (!note.body) return false
    return true
  }

  @Emit()
  transactionChanged (transaction: AccountTransaction | undefined = undefined) {
    return transaction
  }

  @Emit()
  transactionDeleted () {
    return this.transaction
  }

  @Emit()
  exceptionCreated () {
    return this.transaction
  }
}
